import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Layout } from "antd";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { useIsMobile } from "./utils/utilities";
import Header from "./components/Header";
import IdeasDashboardPage from "./modules/ideasDashboard/DashboardPage";
import FeedbackDashboardPage from "./modules/feedbackDashboard/DashboardPage";
import CollaborateDashboardPage from "./modules/collaborateDashboard/DashboardPage";
import SubmissionDashboardPage from "./modules/submissionDashboard/DashboardPage";
import NotFound from "./modules/NotFound.js";
import SideNav from "./components/SideNav";
import LoginPage from "./modules/login/LoginPage";
import SignUpPage from "./modules/signup/SignUpPage";
import SignUpRequested from "./modules/signup/SignUpRequested";
import CharityProfileHome from "./modules/profile/charityProfile/charityProfileHome";
import ForgetPassword from "./modules/login/ForgetPassword";
import ProtectedRoute from "./ProtectedRoute";
import CreateProfile from "./modules/profile/companyProfile/CreateProfile";
import CreateProfileStart from "./modules/profile/companyProfile/CreateProfileStart";
import CreateProfileEntity from "./modules/profile/companyProfile/CreateProfileEntity";
import CreateProfileMedia from "./modules/profile/companyProfile/CreateProfileMedia";
import CreateProfileLogo from "./modules/profile/companyProfile/CreateProfileLogo";
import EditMyProfile from "./modules/profile/companyProfile/EditMyProfile";
import MyProfile from "./modules/profile/companyProfile/MyProfile";
import CreatePassword from "./modules/login/CreatePassword";
import CompanyUser from "./modules/profile/companyProfile/CompanyUser";
import CharityUser from "./modules/profile/charityProfile/CharityUser";
import CompanyApplicationDashboard from "./modules/companyInteractions/Dashboard";
import CharityApplicationDashboard from "./modules/charityInteractions/Dashboard";
import CompanyDonation from "./modules/companyDonations/companyDonationTab";
import CharityDonation from "./modules/charityDonations/charityDonationTab";
import CompanyProgramReview from "./modules/companyProgramReview/ProgramReviewTab";
import CharityProgramReview from "./modules/charityProgramReview/ProgramReviewTab";
import CharityCreateStart from "./modules/charityCreate/CharityCreateStart";
import CharityCreateName from "./modules/charityCreate/CharityCreateName";
import CharityProjectDescription from "./modules/charityCreate/CharityProjectDescription";
import CharityProjectLocation from "./modules/charityCreate/CharityProjectLocation";
import CharityProjectDates from "./modules/charityCreate/CharityProjectDates";
import CharityRequest from "./modules/charityCreate/CharityRequest";
import CharityImpactProgram from "./modules/charityCreate/CharityImpactProgram";
import CharityImpactOpportunity from "./modules/charityCreate/CharityImpactOpportunity";
import CharityImpactOpportunity2 from "./modules/charityCreate/CharityImpactOpportunity2";
import CharityImpactBeneficiaries from "./modules/charityCreate/CharityImpactBeneficiaries";
import CharityImpactCustomQuestions from "./modules/charityCreate/CharityImpactCustomQuestions";
import CharityAwardCriteria from "./modules/charityCreate/CharityAwardCriteria";
import CharityAdminDetails from "./modules/charityCreate/CharityAdminDetails";
import CharityCreatePreview from "./modules/charityCreate/charityReview/CharityCreateReviewTab";
import CharityIndicator from "./modules/charityCreate/CharityIndicator";
import ProgrammesList from "./modules/companyCreate/Programmes";
import CompanyCreateStart from "./modules/companyCreate/CompanyCreateStart";
import CompanyCreateName from "./modules/companyCreate/CompanyCreateName";
import CompanyCreateLocation from "./modules/companyCreate/CompanyCreateLocation";
import CompanyCreateDetails from "./modules/companyCreate/CompanyCreateDetails";
import CompanySupport from "./modules/companyCreate/CompanySupport";
import CompanyTimeline from "./modules/companyCreate/CompanyTimeline";
import CompanyEntities from "./modules/companyCreate/CompanyEntities";
import CompanyApplicationQuestion from "./modules/companyCreate/CompanyApplicationQuestion";
import CompanyImpactStrategy from "./modules/companyCreate/CompanyImpactStrategy";
import CompanyMeasuringImpact from "./modules/companyCreate/CompanyMeasuringImpact";
import CompanyMeasuringApproval from "./modules/companyCreate/CompanyMeasuringApproval";
import CompanyCreateAdmin from "./modules/companyCreate/CompanyCreateAdmin";
import CompanyCreatePreview from "./modules/companyCreate/companyReview/CompanyCreateReviewTab";
import CharityApplicationCreatePreview from "./modules/charityCreate/charityReview/CharityApplicationCreateReviewTab";
import CharityProgramReviewTab from "./modules/charityCreate/charityProgramReview/ProgramReviewTab"
import CompanyProgramReviewTab from "./modules/companyCreate/companyProgramReview/CompanyProgramReviewTab";
import CharityProfileCreateStart from "./modules/charityProfileCreate/CharityProfileCreateStart";
import CharityEntityName from "./modules/charityProfileCreate/CharityEntityName";
import CharityProfileLegalStructure from "./modules/charityProfileCreate/CharityProfileLegalStructure";
import CharityProfileTrustees from "./modules/charityProfileCreate/CharityProfileTrustees";
import CharityProfileLocations from "./modules/charityProfileCreate/CharityProfileLocation";
import CharityProfileDescription from "./modules/charityProfileCreate/CharityProfileDescription";
import CharityProfileMedia from "./modules/charityProfileCreate/CharityProfileMedia";
import CharityProfilePreview from "./modules/charityProfileCreate/CharityProfilePreview";
import CharityProfileReview from "./modules/charityProfileCreate/CharityProfileReview";
import CharityProfileEnd from "./modules/charityProfileCreate/CharityProfileEnd";
import CharityDiscoverFundingOpportunities from "./modules/charityProfileCreate/CharityDiscoverFundingOpportunities";
import CharityContent from "./modules/charityProfileCreate/CharityContent";
import CompanyContent from "./modules/charityProfileCreate/CompanyContent";
import CharityInterimCreateStart from "./modules/charityInterimContent/CharityInterimCreateStart";
import CharityInterimCreateReport from "./modules/charityInterimContent/CharityInterimCreateReport";
import CharityInterimCreatePreview from "./modules/charityInterimContent/CharityInterimCreatePreviewTab";
import CharityFinalCreateStart from "./modules/charityFinalContent/CharityFinalCreateStart";

const getPaddingValue = (props) => {
  const menuPadding = props?.menuCollapsed ? '80px' : '200px';
  return props?.loginPagesPath ? '35px' : menuPadding;
}

const StyledMainContainer = styled.div`
  margin-top: 41px;
  padding-left: ${props => getPaddingValue(props)};
  background: #ffffff;
`;

const RootRoute = ({ userType }) => {
  const isMobile = useIsMobile();
  const [menuCollapsed, setMenuCollapsed] = useState(true);
  const [tokenAvailable, setTokenAvailable] = useState(false);
  const [isLoginPagesPath, setIsLoginPagesPath] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const isTokenAvailable = window.localStorage.getItem('id_Token');
    const createProfileRoutes = ["/",
      "/login",
      "/signup",
      "/forgot-password",
      "/create-password",
      "/signup-requested",
      "/company/profile/create/entity",
      "/company/profile/create/start",
      "/company/profile/create/media",
      "/company/profile/create/logo",
      "/company/my-profile/edit",
      "/impact-maker/applications/create/start",
      "/impact-maker/applications/create/name",
      "/impact-maker/applications/create/description",
      "/impact-maker/applications/create/location",
      "/impact-maker/applications/create/dates",
      "/impact-maker/applications/create/request",
      "/impact-maker/applications/create/impact-programme-custom",
      "/impact-maker/applications/create/impact-opportunity-custom",
      "/impact-maker/applications/create/impact-project-custom",
      "/impact-maker/applications/create/beneficiaries",
      "/impact-maker/applications/create/custom-questions",
      "/impact-maker/applications/create/award-criteria",
      "/impact-maker/applications/create/admin-details",
      "/impact-maker/applications/create/indicators",
      "/impact-maker/applications/create/preview",
      "/company/programme/create/name",
      "/company/programme/create/state",
      "/company/programme/create/location",
      "/company/programme/create/details",
      "/company/programme/create/support",
      "/company/programme/create/timelines",
      "/company/programme/create/entities",
      "/company/programme/create/questions",
      "/company/programme/create/impact",
      "/company/programme/create/measure",
      "/company/programme/create/approval",
      "/company/programme/create/admin",
      "/company/programme/create/preview",
      "/public/",
      "/impact-maker/profile/create/start",
      "/impact-maker/profile/create/entity-name",
      "/impact-maker/profile/create/legal-structure",
      "/impact-maker/profile/create/trustees",
      "/impact-maker/profile/create/locations",
      "/impact-maker/profile/create/description",
      "/impact-maker/profile/create/media",
      "/impact-maker/profile/create/preview",
      "/impact-maker/profile/create/profile-end",
      "/impact-maker/content/interim/start",
      "/impact-maker/content/interim/report",
      "/impact-maker/content/interim/preview"
    ].includes(location?.pathname)
    const showSideNav = !!isTokenAvailable && !createProfileRoutes;
    setTokenAvailable(showSideNav);
    const loginPagesPath = ["/", "/login", "/signup", "/forgot-password", "/signup-requested", "/create-password"];
    const isMenuCollapsed = loginPagesPath.includes(location.pathname) || false;
    setMenuCollapsed(!isMenuCollapsed)
    setIsLoginPagesPath(createProfileRoutes)
  }, [location]);

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      {tokenAvailable && <SideNav
        setMenuCollapsed={setMenuCollapsed}
        menuCollapsed={menuCollapsed}
        userType={userType}
      />}
      <Layout>
        <Header isMobile={isMobile} menuCollapsed={menuCollapsed} />
        <StyledMainContainer className="appMainContainer" menuCollapsed={menuCollapsed} loginPagesPath={isLoginPagesPath}>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/signup-requested" element={<SignUpRequested />} />
            <Route path="/charity-profile-home" element={<ProtectedRoute><CharityProfileHome /> </ProtectedRoute>} />
            <Route path="/company-profile-home" element={<ProtectedRoute><CharityProfileHome /></ProtectedRoute>} />
            <Route path="/company/submissions/ideas" element={<ProtectedRoute><IdeasDashboardPage /></ProtectedRoute>} />
            <Route path="/company/submissions/ideas-data" element={<ProtectedRoute><IdeasDashboardPage /></ProtectedRoute>} />
            <Route path="/company/submissions/feedback" element={<ProtectedRoute><FeedbackDashboardPage /></ProtectedRoute>} />
            <Route path="/company/submissions/feedback-data" element={<ProtectedRoute><FeedbackDashboardPage /></ProtectedRoute>} />
            <Route path="/company/submissions/collaborate" element={<ProtectedRoute><CollaborateDashboardPage /></ProtectedRoute>} />
            <Route path="/company/submissions/collaborate-data" element={<ProtectedRoute><CollaborateDashboardPage /></ProtectedRoute>} />
            <Route path="/company/submissions" element={<ProtectedRoute><SubmissionDashboardPage /></ProtectedRoute>} />
            <Route path="/company-profile-home" element={<ProtectedRoute><CharityProfileHome /></ProtectedRoute>} />
            <Route path="/company/profile" element={<ProtectedRoute><CreateProfile /></ProtectedRoute>} />
            <Route path="/company/profile/create/entity" element={<ProtectedRoute><CreateProfileEntity /></ProtectedRoute>} />
            <Route path="/company/profile/create/start" element={<ProtectedRoute><CreateProfileStart /></ProtectedRoute>} />
            <Route path="/company/profile/create/media" element={<ProtectedRoute><CreateProfileMedia /></ProtectedRoute>} />
            <Route path="/company/profile/create/logo" element={<ProtectedRoute><CreateProfileLogo /></ProtectedRoute>} />
            <Route path="/company/my-profile/edit" element={<ProtectedRoute><EditMyProfile /></ProtectedRoute>} />
            <Route path="/impact-maker/applications/actions" element={<ProtectedRoute><CharityApplicationDashboard /></ProtectedRoute>} />
            <Route path="/impact-maker/applications/pending" element={<ProtectedRoute><CharityApplicationDashboard /></ProtectedRoute>} />
            <Route path="/impact-maker/applications/approved" element={<ProtectedRoute><CharityApplicationDashboard /></ProtectedRoute>} />
            <Route path="/impact-maker/applications/declined" element={<ProtectedRoute><CharityApplicationDashboard /></ProtectedRoute>} />
            <Route path="/company/applications/:programID?/:opportunityID?" element={<ProtectedRoute><CompanyProgramReviewTab /></ProtectedRoute>} />
            <Route path="/company/applications/actions" element={<ProtectedRoute><CompanyApplicationDashboard /></ProtectedRoute>} />
            <Route path="/company/applications/pending" element={<ProtectedRoute><CompanyApplicationDashboard /></ProtectedRoute>} />
            <Route path="/company/applications/pre-approved" element={<ProtectedRoute><CompanyApplicationDashboard /></ProtectedRoute>} />
            <Route path="/company/applications/approved" element={<ProtectedRoute><CompanyApplicationDashboard /></ProtectedRoute>} />
            <Route path="/company/applications/declined" element={<ProtectedRoute><CompanyApplicationDashboard /></ProtectedRoute>} />
            <Route path="/company/my-profile" element={<ProtectedRoute><MyProfile /></ProtectedRoute>} />
            <Route path="/company/donations" element={<ProtectedRoute><CompanyDonation /></ProtectedRoute>} />
            <Route path="/create-password/:username?" element={<CreatePassword />} />
            <Route path="/company/users" element={<CompanyUser />} />
            <Route path="/impact-maker/donations" element={<ProtectedRoute><CharityDonation /></ProtectedRoute>} />
            <Route path="/company/programmes/review/:programID?" element={<ProtectedRoute><CompanyProgramReview /></ProtectedRoute>} />
            <Route path="/impact-maker/programme/:programID?/:opportunityId?" element={<ProtectedRoute><CharityProgramReview /></ProtectedRoute>} />
            <Route path="/impact-maker/applications/create/start" element={<ProtectedRoute><CharityCreateStart /></ProtectedRoute>} />
            <Route path="/impact-maker/applications/create/name" element={<ProtectedRoute><CharityCreateName /></ProtectedRoute>} />
            <Route path="/impact-maker/applications/create/description" element={<ProtectedRoute><CharityProjectDescription /></ProtectedRoute>} />
            <Route path="/impact-maker/applications/create/location" element={<ProtectedRoute><CharityProjectLocation /></ProtectedRoute>} />
            <Route path="/impact-maker/applications/create/dates" element={<ProtectedRoute><CharityProjectDates /></ProtectedRoute>} />
            <Route path="/impact-maker/applications/create/request" element={<ProtectedRoute><CharityRequest /></ProtectedRoute>} />
            <Route path="/impact-maker/applications/create/impact-programme-custom" element={<ProtectedRoute><CharityImpactProgram /></ProtectedRoute>} />
            <Route path="/impact-maker/applications/create/impact-opportunity-custom" element={<ProtectedRoute><CharityImpactOpportunity /></ProtectedRoute>} />
            <Route path="/impact-maker/applications/create/impact-project-custom" element={<ProtectedRoute><CharityImpactOpportunity2 /></ProtectedRoute>} />
            <Route path="/impact-maker/applications/create/beneficiaries" element={<ProtectedRoute><CharityImpactBeneficiaries /></ProtectedRoute>} />
            <Route path="/impact-maker/applications/create/custom-questions" element={<ProtectedRoute><CharityImpactCustomQuestions /></ProtectedRoute>} />
            <Route path="/impact-maker/applications/create/indicators" element={<ProtectedRoute><CharityIndicator /></ProtectedRoute>} />
            <Route path="/impact-maker/applications/create/award-criteria" element={<ProtectedRoute><CharityAwardCriteria /></ProtectedRoute>} />
            <Route path="/impact-maker/applications/create/admin-details" element={<ProtectedRoute><CharityAdminDetails /></ProtectedRoute>} />
            <Route path="/impact-maker/applications/create/preview" element={<ProtectedRoute><CharityCreatePreview /></ProtectedRoute>} />
            <Route path="/company/programmes" element={<ProtectedRoute><ProgrammesList /></ProtectedRoute>} />
            <Route path="/company/programme/create/start" element={<ProtectedRoute><CompanyCreateStart /></ProtectedRoute>} />
            <Route path="/company/programme/create/name" element={<ProtectedRoute><CompanyCreateName /></ProtectedRoute>} />
            <Route path="/company/programme/create/location" element={<ProtectedRoute><CompanyCreateLocation /></ProtectedRoute>} />
            <Route path="/company/programme/create/details" element={<ProtectedRoute><CompanyCreateDetails /></ProtectedRoute>} />
            <Route path="/company/programme/create/support" element={<ProtectedRoute><CompanySupport /></ProtectedRoute>} />
            <Route path="/company/programme/create/timelines" element={<ProtectedRoute><CompanyTimeline /></ProtectedRoute>} />
            <Route path="/company/programme/create/entities" element={<ProtectedRoute><CompanyEntities /></ProtectedRoute>} />
            <Route path="/company/programme/create/questions" element={<ProtectedRoute><CompanyApplicationQuestion /></ProtectedRoute>} />
            <Route path="/company/programme/create/impact" element={<ProtectedRoute><CompanyImpactStrategy /></ProtectedRoute>} />
            <Route path="/company/programme/create/measure" element={<ProtectedRoute><CompanyMeasuringImpact /></ProtectedRoute>} />
            <Route path="/company/programme/create/approval" element={<ProtectedRoute><CompanyMeasuringApproval /></ProtectedRoute>} />
            <Route path="/company/programme/create/admin" element={<ProtectedRoute><CompanyCreateAdmin /></ProtectedRoute>} />
            <Route path="/company/programme/create/preview" element={<ProtectedRoute><CompanyCreatePreview /></ProtectedRoute>} />
            <Route path="/impact-maker/programme/:programID?" element={<ProtectedRoute><CharityApplicationCreatePreview /></ProtectedRoute>} />
            <Route path="/impact-maker/discover" element={<ProtectedRoute><CharityDiscoverFundingOpportunities /></ProtectedRoute>} />
            <Route path="/impact-maker/applications/:programID?/:opportunityID?" element={<ProtectedRoute><CharityProgramReviewTab /></ProtectedRoute>} />
            <Route path="/impact-maker/profile/create/start" element={<ProtectedRoute><CharityProfileCreateStart /></ProtectedRoute>} />
            <Route path="/impact-maker/profile/create/entity-name" element={<ProtectedRoute><CharityEntityName /></ProtectedRoute>} />
            <Route path="/impact-maker/profile/create/legal-structure" element={<ProtectedRoute><CharityProfileLegalStructure /></ProtectedRoute>} />
            <Route path="/impact-maker/content" element={<ProtectedRoute><CharityContent /></ProtectedRoute>} />
            <Route path="/company/content" element={<ProtectedRoute><CompanyContent /></ProtectedRoute>} />  
            <Route path="/impact-maker/profile/create/trustees" element={<ProtectedRoute><CharityProfileTrustees /></ProtectedRoute>} />
            <Route path="/impact-maker/profile/create/locations" element={<ProtectedRoute><CharityProfileLocations /></ProtectedRoute>} />
            <Route path="/impact-maker/profile/create/description" element={<ProtectedRoute><CharityProfileDescription /></ProtectedRoute>} />
            <Route path="/impact-maker/profile/create/media" element={<ProtectedRoute><CharityProfileMedia /></ProtectedRoute>} />
            <Route path="/impact-maker/profile/create/preview" element={<ProtectedRoute><CharityProfilePreview /></ProtectedRoute>} />
            <Route path="/impact-maker/profile" element={<ProtectedRoute><CharityProfileReview /></ProtectedRoute>} />
            <Route path="/impact-maker/profile/create/profile-end" element={<ProtectedRoute><CharityProfileEnd /></ProtectedRoute>} />
            <Route path="/impact-maker/content/interim/start/:ID?" element={<ProtectedRoute><CharityInterimCreateStart/></ProtectedRoute>} />
            <Route path="/impact-maker/content/interim/report/:ID?" element={<ProtectedRoute><CharityInterimCreateReport /></ProtectedRoute>} />
            <Route path="/impact-maker/content/interim/preview/:ID?" element={<ProtectedRoute><CharityInterimCreatePreview /></ProtectedRoute>} />
            <Route path="/impact-maker/content/final/start/:ID?" element={<ProtectedRoute><CharityFinalCreateStart/></ProtectedRoute>} />
            <Route path="/public" element={<CharityApplicationCreatePreview />} />
            <Route path="/impact-maker/users" element={<CharityUser />} />
            <Route path="/forgot-password/:email?" element={<ForgetPassword />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </StyledMainContainer>
      </Layout>
    </Layout>
  );
};

const BaseRoutes = ({ userType }) => {
  return (
    <Router>
      <RootRoute userType={userType} />
    </Router>
  );
};

export default BaseRoutes;
