import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { Table, Space, Select, Form, DatePicker, Spin } from "antd";
import { Helmet as HelmetAsync } from "react-helmet-async";
import styled from "styled-components";
import { useIntl } from "react-intl";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";
import {
    contentListSelector,
    interactionSelector,
} from "../../redux/selectors";
import StyledText from "../../components/StyledText";
import {
    getContentList,
    getInteraction
} from "../../redux/actions";
import { formatDate, setLocalStorageItem } from "../../utils/utilities";

const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 20 },
};

function formatToYYYYMMDD(dateString) {
    const date = new Date(dateString);
    return date.getUTCFullYear() +
        String(date.getUTCMonth() + 1).padStart(2, '0') +
        String(date.getUTCDate()).padStart(2, '0');
}

const CharityContent = ({ className }) => {
    const intl = useIntl();
    const baseClassName = clsx("charityContent", className);
    const dispatch = useAppDispatch();
    const contentListData = useAppSelector(contentListSelector);
    const interactionsData = useAppSelector(interactionSelector);
    const [form] = Form.useForm();
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();
    const { reportType, programID, startDate, closedDate } = formData || {};

    useEffect(() => {
        dispatch(getContentList())
        dispatch(getInteraction({
            sortBy: "opportunityName",
            asc: "true",
            filterBy: {
                status: [
                    "Approved",
                    "Completed"
                ]
            },
            limit: "100"
        })
        )
    }, []);

    useEffect(() => {
        if (programID || reportType || (startDate && closedDate)) {
            dispatch(getContentList({
                type: reportType,
                programID,
                startDate: startDate ? formatToYYYYMMDD(startDate) : undefined,
                closedDate: closedDate ? formatToYYYYMMDD(closedDate) : undefined,
            }))
        }
    }, [programID, reportType, startDate, closedDate]);

    const getTableData = (list, listParent) => {
        if (list?.length > 0) {
            return list?.map((item, idx) => {
                const {
                    type,
                    interactionDate,
                    status,
                    ID,
                } = item || {};
                return {
                    key: idx,
                    type,
                    interactionDate,
                    status,
                    ID,
                    ...listParent,
                };
            });
        }
        return null;
    }

    const columns = [
        {
            title: <StyledText as="p" variant="H3">
                {intl.formatMessage({ id: "report" })}
            </StyledText>,
            dataIndex: 'report',
            key: 'report',
            render: (_, { type }) => (
                <div className="nameContainer">
                    <div className="blockWithText">
                        {type}
                    </div>
                </div>
            ),
            fixed: 'left',
        },
        {
            title: <StyledText as="p" variant="H3">
                {intl.formatMessage({ id: "due_date" })}
            </StyledText>,
            dataIndex: 'interactionDate',
            key: 'interactionDate',
            render: (_, { interactionDate }) => {
                return (<StyledText as="p" variant="B3">
                    {formatDate(interactionDate)}
                </StyledText>)
            }
        },
        {
            title: <StyledText as="p" variant="H3">
                {intl.formatMessage({ id: "status" })}
            </StyledText>,
            dataIndex: 'status',
            key: 'status',
            render: (_, { status, overdue }) => {
                return overdue === "Y" ? <StyledText as="p" variant="B3f">
                    {overdue}
                </StyledText> : <StyledText as="p" variant="B3">
                    {status}
                </StyledText>
            }
        },
        {
            title: <StyledText as="p" variant="H3">
                {intl.formatMessage({ id: "action" })}
            </StyledText>,
            key: 'actions',
            dataIndex: 'action',
            render: (_, report) => {
                const getButtonText = () => {
                    if (report?.status === "Submitted") {
                        return intl.formatMessage({ id: "view_report" });
                    } else if (report?.status === "Pending") {
                        return intl.formatMessage({ id: "start_report" });
                    } else if (report?.status === "Edit") {
                        return intl.formatMessage({ id: "complete_report" });
                    } else {
                        return "";
                    }
                };

                const getNavigationPath = () => {
                    if (report?.status === "Submitted") {
                        return `/impact-maker/content/${report?.ID}`;
                    } else if (report?.status === "Pending") {
                        if (report?.type === "interim") {
                            return `/impact-maker/content/interim/start/${report?.ID}`;
                        } else if (report?.type === "final") {
                            return `/impact-maker/content/final/start/${report?.ID}`;
                        } else {
                            return "";
                        }
                    } else if (report?.status === "Edit") {
                        return `/impact-maker/content/create/start/${report?.ID}`;
                    } else {
                        return "";
                    }
                };

                const handleButtonClick = () => {
                     setLocalStorageItem('contentData', JSON.stringify(report))
                    const path = getNavigationPath();
                    if (path) navigate(path);
                };

                return (
                    <Space size="middle">
                        <button className="button" onClick={handleButtonClick}>
                            {getButtonText()}
                        </button>
                    </Space>
                );
            },
            fixed: 'right',
        },
    ];

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const onFormChange = (_, allValues) => {
        setFormData(allValues);
    };

    const isValid = Array.isArray(contentListData) && contentListData?.length === 0

    return (
        <div className={baseClassName}>
            <HelmetAsync>
                <title>Charity content Page</title>
            </HelmetAsync>
            {!contentListData?.length && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={!contentListData?.length} />
                </div>
            )}
            <div className='subContentContainer'>
                <StyledText as="p" variant="H1" className='mainTitle'>
                    {intl.formatMessage({ id: "content" })}
                </StyledText>
                <Form
                    {...formItemLayout}
                    labelAlign="left"
                    form={form}
                    onFinishFailed={onFinishFailed}
                    className="formContainer"
                    scrollToFirstError
                    onValuesChange={onFormChange}
                >
                    <Form.Item name="programID">
                        <Select
                            allowClear
                            className="selectBox"
                            placeholder={intl.formatMessage({ id: "select_programme" })}
                            type="formSelect"
                            options={interactionsData?.map((el) => ({
                                key: el?.programID,
                                label: el?.programName,
                                value: el?.programID,
                            }))}
                        />
                    </Form.Item>
                    <Form.Item name="reportType">
                        <Select
                            allowClear
                            className="selectBox"
                            placeholder={intl.formatMessage({ id: "select_report_type" })}
                            type="formSelect"
                            options={[{
                                key: 'Interim',
                                label: 'Interim',
                                value: 'interim'
                            }, {
                                key: 'Final',
                                label: 'Final',
                                value: 'final'
                            },]}
                        />
                    </Form.Item>
                    <div className="dateContainer">
                        <Form.Item
                            name="startDate"
                            className="formItem smallInput selectBox"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue("closedDate")) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error("Please select an end date if a start date is chosen.")
                                        );
                                    },
                                }),
                            ]}
                        >
                            <DatePicker
                                format="YYYY-MM-DD"
                                placeholder="Enter start date"
                                onChange={() => {
                                    form.validateFields(["closedDate"]);
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="closedDate"
                            className="formItem smallInput selectBox"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue("startDate")) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error("Please select a start date if an end date is chosen.")
                                        );
                                    },
                                }),
                            ]}
                        >
                            <DatePicker
                                format="YYYY-MM-DD"
                                placeholder="Enter end date"
                                onChange={() => {
                                    form.validateFields(["startDate"]);
                                }}
                            />
                        </Form.Item>
                    </div>
                </Form>
                {contentListData?.map((el) => <div key={el?.opportunityName} className="mainContent">
                    <StyledText as="p" variant="H2" className='subTitle'>
                        {`${intl.formatMessage({ id: "project" })}: ${el?.opportunityName}`}
                    </StyledText>
                    <StyledText as="p" variant="H3" className='subTitle'>
                        {el?.programName}
                    </StyledText>
                    <Table columns={columns} loading={isValid} dataSource={getTableData(el?.contentList, el)} />
                </div>)}
            </div>
        </div>
    );
};

const StyledCharityContent = styled(CharityContent)`
  &.charityContent {
    background: ${(props) => props.theme.backgroundCanvas};
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 60px 10px;
    .subContentContainer{
        display: grid;
        gap: 20px;
    }
    .formContainer{
        display: flex;
        gap: 50px;
        .dateContainer{
            display: flex;
            gap: 5px;  
            .ant-picker-outlined{
                width: 165px;
            }
        }
    }
    .mainTitle{
        margin-bottom: 20px;
    }
    .mainContent{
        background: white;
        padding: 20px;
        border-radius: 10px;
    }
    .subTitle{
        display: flex;
        white-space: nowrap;
        align-items: center;
        gap: 5px;
        margin-bottom: 20px;
    }
    .selectBox{
        width: 200px;
    }
    .nameContainer{
      display: flex;
      gap: 10px;
    }
    .ant-tabs-tab-active {
      .title {
        color: ${(props) => props.theme.bizGiveThemeColor} !important;
      }
    }
    .ant-tabs-ink-bar {
      background: ${(props) => props.theme.bizGiveThemeColor} !important;
    }
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }
    .button{
        border: 0;
        background: transparent;
        color: #5550F7;
        cursor: pointer;
        min-width: 100px;
    }
  }
`;

export default StyledCharityContent;
