import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Helmet as HelmetAsync } from "react-helmet-async";
import {
    EnvironmentFilled,
    StarFilled,
    CalendarFilled
} from "@ant-design/icons";
import clsx from "clsx";
import { Tabs, Layout, Divider, Timeline, Form, Checkbox } from 'antd';
import { useIntl } from 'react-intl';
import styled from "styled-components";
import {
    useAppSelector,
    useAppDispatch
} from "../../redux/store";
import {
    programDataSelector,
    opportunityDataSelector,
    profileSelector,
} from "../../redux/selectors";
import MapBoxComponent from "../../components/MapBoxComponent";
import FullScreenWrapper from '../../components/FullscreenWrapper';
import { getProfileData, getContentList, getOpportunity, getProgramById } from '../../redux/actions';
import { getSecuredUrl, getLocalStorageItem } from "../../utils/utilities";
import DateFormatter from "../../components/DateFormatter";
import ProgramReview from './CharityInterimCreatePreview';
import StyledText from "../../components/StyledText";
import Button from '../../components/Button';
import CustomModal from "../../components/Modal";

const getUpdateDueDateMessage = ({
    updateDueDateRelativeDays,
    updateDueDate,
    currentBudgetStartDate,
    currentBudgetEndDate
}) => {
    if (updateDueDateRelativeDays) {
        if (currentBudgetStartDate && currentBudgetEndDate) {
            return `Every ${updateDueDateRelativeDays} days during the project running dates`;
        } else {
            return `${updateDueDateRelativeDays} days after the project start date`;
        }
    } else if (updateDueDate) {
        return updateDueDate.join(', ');
    } else {
        return 'Not Required';
    }
}

const ProgramReviewHomePage = ({ className }) => {
    const baseClassName = clsx("dashboardPage", className);
    const [logoS3URL, setLogoS3URL] = useState('');
    const [submitApplicationModal, setSubmitApplicationModal] = useState(false);
    const [logoImageURL, setLogoImageURL] = useState('');
    const programData = useAppSelector(programDataSelector);
    const intl = useIntl();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { charity: { logoS3URL: logoImage } = {} } = useAppSelector(profileSelector) || {};
    const opportunityData = useAppSelector(opportunityDataSelector) || {};
    const [isFullscreen, setIsFullscreen] = useState(false);
    const { opportunityID: opportunityId } = JSON.parse(getLocalStorageItem('contentData') || '{}');
    const { ID } = useParams();

    const {
        currentBudgetStartDate,
        currentBudgetEndDate,
        programmeLocation,
        updateDueDateRelativeDays,
        outcomeDueDateRelativeDays,
        programName,
        contentAgreement
    } = programData || {};

    const { opportunity:
        { startDate,
            endDate,
            coverImage,
            donationDeadLineDate,
            updateDueDate,
            outComeDate,
            opportunityName,
            programID,
            opportunityLocation2: {
                features
            } = {},
        } = {} }
        = opportunityData || {};

    const setLogoImage = async fileKey => {
        const securedURI = await getSecuredUrl(fileKey);
        const securedURL = URL.createObjectURL(securedURI);
        setLogoS3URL(securedURL);
        return fileKey ? securedURL : '';
    };

    const setLogoBannerImage = async fileKey => {
        const securedURI = await getSecuredUrl(fileKey);
        const securedURL = URL.createObjectURL(securedURI);
        setLogoImageURL(securedURL);
        return fileKey ? securedURL : '';
    };

    useEffect(() => {
        if (opportunityId) {
            dispatch(getOpportunity(opportunityId));
        }
    }, [opportunityId]);

    useEffect(() => {
        if (opportunityName) {
            dispatch(getProfileData());
            dispatch(getContentList({
                ID: ID
            }));
            dispatch(getProgramById(programID))
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [opportunityName]);

    useEffect(() => {
        if (logoImage?.[0]?.key) {
            console.log("logoImage", logoImage)
            setLogoBannerImage(logoImage?.[0]?.key)
            setLogoImage(coverImage)
        }
    }, [logoImage])

    const renderCoverImage = () => {
        if (logoS3URL && typeof logoS3URL === 'string') return <img alt="coverdd" className="cameraIcon" src={logoS3URL} />;
        return '';
    };

    const updateDueDateMessage = getUpdateDueDateMessage({
        updateDueDateRelativeDays,
        updateDueDate,
        currentBudgetStartDate,
        currentBudgetEndDate
    });

    const items = [
        {
            key: "application",
            label: <StyledText as="p" variant="B3" className="title">
                {intl.formatMessage({ id: "report" })}
            </StyledText>,
            children: <ProgramReview />,
        },
    ];

    const handlePrevious = () => {
        navigate(`/impact-maker/content/interim/report/${ID}`);
    };

    return (
        <div className={baseClassName}>
            <HelmetAsync>
                <title>Programme Review Page</title>
            </HelmetAsync>
            <div className='leftContainer'>
                <div >
                    <Layout className="bannerImageContainer">
                        {renderCoverImage()}
                    </Layout>
                    <div className='companyTitleContainer'>
                        <div className='imageContainer'>{logoImageURL && <img src={logoImageURL} alt="logoImage" />}</div>
                        <div>
                            <StyledText as="p" variant="H1c" className="title">
                                {opportunityName}
                            </StyledText>
                            <StyledText as="span" variant="B3b">
                                {programName}
                            </StyledText>
                        </div>
                    </div>
                </div>
                <Tabs defaultActiveKey={items[0]} items={items} />
            </div>
            <div className="rightSideContainer">
                <FullScreenWrapper isFullscreen={isFullscreen}
                    setIsFullscreen={setIsFullscreen}>
                    <MapBoxComponent
                        zoom={4}
                        defaultStyle="MonoCrome"
                        id="submission"
                        markers={features}
                        geoJsonData={programmeLocation}
                        hideStyles
                        isFullscreen={isFullscreen}
                        hideZoomControls
                        styles="mapbox://styles/mapbox/outdoors-v12"
                    />
                </FullScreenWrapper>
                <div className="rightContainer">
                    <div className="locationContainer subContainer">
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "project_location" })}
                        </StyledText>
                        {features?.map((item) => <div key={item} className="locationIcon">
                            <EnvironmentFilled />
                            <StyledText as="p" variant="B3" key={item} className="city">
                                {item?.place_name === 'anyLocation'
                                    ? intl.formatMessage({ id: 'any_location' })
                                    : (item.preferred_name || item?.place_name)}
                            </StyledText>
                        </div>)}
                    </div>
                    <Divider />
                    <StyledText as="p" variant="H2" className="subContainer">
                        {intl.formatMessage({ id: "project_timelines" })}
                    </StyledText>
                    <Timeline
                        items={[
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "project_running_dates" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /> <StyledText as="p" variant="B3c">
                                                <>
                                                    <DateFormatter dateValue={startDate?.trim()} /> - <DateFormatter dateValue={endDate?.trim()} />
                                                </>
                                            </StyledText></div>
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "donations_required_before" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /><StyledText as="p" variant="B3c">
                                                <DateFormatter dateValue={donationDeadLineDate?.trim()} />
                                            </StyledText></div>
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "interim_updates" })}
                                    </StyledText>
                                        {Array.isArray(updateDueDate) && updateDueDate?.map((el) => <div key={el} className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /><StyledText as="p" variant="B3c">
                                                {el ? <DateFormatter dateValue={el} /> : updateDueDateMessage}
                                            </StyledText></div>)}
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "final_report" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /><StyledText as="p" variant="B3c">
                                                {<DateFormatter dateValue={outComeDate?.trim()} /> || `${outcomeDueDateRelativeDays} after the project end date`}
                                            </StyledText></div>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
            <div className="buttonContainer">
                <Divider />
                <div className="buttonContentContainer">
                    <div className="nextAndPreviousContainer">
                        <Button variant="secondary" type="button" onClick={() => handlePrevious()}>
                            {intl.formatMessage({ id: "previous" })}
                        </Button>
                        <Button variant="primary" onClick={() => setSubmitApplicationModal(true)}>
                            {intl.formatMessage({ id: "next" })}
                        </Button>
                    </div>
                    <div className="saveAndExitButton">
                        <Button variant="secondary" htmlType="button" onClick={() => setSubmitApplicationModal(true)}>
                            {intl.formatMessage({ id: "save_n_exit" })}
                        </Button>
                    </div>
                </div>
            </div>
            <CustomModal
                title={intl.formatMessage({ id: 'submit_your_report' })}
                open={submitApplicationModal}
                onOk={() => setSubmitApplicationModal(false)}
                onCancel={() => setSubmitApplicationModal(false)}
                closable={false}
                footer={null}
            >
                <Form
                    form={form}
                    onFinish={(values) => {
                        setSubmitApplicationModal(false)
                        navigate('/impact-maker/content');
                    }}
                >
                    <StyledText as="p" variant="B3" className="title">
                        {contentAgreement || intl.formatMessage({ id: "please_confirm_that_all_necessary_copyrights_and_acknowledgements_have_been_added" })}
                    </StyledText>
                    <Form.Item
                        name="contentAgreementConfirmation"
                        valuePropName="checked"
                        rules={[
                            { required: true, message: intl.formatMessage({ id: "please_select" }) }
                        ]}
                    >
                        <div className="checkboxWrapper">
                            <Checkbox>
                                {contentAgreement
                                    ? intl.formatMessage({ id: 'i_have_read_and_agree_to_the_above_terms' })
                                    : intl.formatMessage({ id: 'i_confirm_that_all_necessary_copyrights_and_acknowledgements_have_been_added' })}
                            </Checkbox>
                        </div>
                    </Form.Item>
                    <div className="dFlexModal">
                        <Button
                            variant="secondary"
                            htmlType="button"
                            onClick={() => setSubmitApplicationModal(false)}
                        >
                            {intl.formatMessage({ id: "cancel" })}
                        </Button>
                        <Button
                            variant="primary"
                            htmlType="submit"
                        >
                            {intl.formatMessage({ id: "submit" })}
                        </Button>
                    </div>
                </Form>
            </CustomModal>
        </div>
    )
}

const StyledDashboardPage = styled(ProgramReviewHomePage)`
    &.dashboardPage {
        background: ${(props) => props.theme.backgroundCanvas};
        min-height: 100vh;
        display: flex;
        padding: 20px;
        gap: 20px;

        .flexColumn{
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .ant-tabs-tab-active .title {
            color: ${(props) => props.theme.bizGiveThemeColor} !important;
        }

        .ant-tabs-ink-bar {
            background: ${(props) => props.theme.bizGiveThemeColor} !important;
        }

        .checkBoxWrapper{
            display: flex;
            align-items: center;
            gap: 10px;   
            position: relative;}

        .companyTitleContainer {
            display: flex;
            padding: 10px 70px 10px 200px;
            justify-content: space-between;
            position: relative;
            align-items: center;

            .imageContainer {
                height: 144px;
                width: 144px;
                border-radius: 50%;
                top: -80px;
                left: 25px;
                position: absolute;

                img {
                    height: 144px;
                    width: 144px;
                    border-radius: 50%;
                    border: 1px solid black;
                }
            }
        }

        .bannerImageContainer {
            width: 100%;
            height: 216px;
            overflow: hidden;
            position: relative;
            background: white;
            border-radius: 10px;
        }

        .leftContainer {
            display: flex;
            flex-direction: column;
            gap: 10px;
            overflow: scroll;
            height: 90vh;
            width: 95%;

            svg {
                fill: #04ac9c !important;
            }

            .customAlert {
                svg {
                    fill: red !important;
                }
            }
        }

        .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
        }

        ul {
            margin: 0;
        }

        .ant-collapse-expand-icon {
            color: #313131 !important;
        }

        .mapIFrameStyle {
            border: 0;
        }

        .locationIcon {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        .locationContainer {
            margin-top: 50px;

            .locationDistance {
                font-style: italic;
            }

            svg {
                color: #f96675;
            }
        }

        .subContainer {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .descriptionContainer {
                p, li {
                    font-size: 14px;
                    font-weight: 500;
                    color: #242424;
                    font-family: sans-serif;
                }
            }
        }

        .ant-timeline-item-head {
            background: transparent !important;
        }

        .anticon-check {
            color: #58b55e;
        }

        .rightSideContainer {
            overflow: scroll;
            height: 90vh;
            width: 25vw;

            .rightContainer {
                display: flex;
                flex-direction: column;
                gap: 20px;
                background: white;
                padding: 12px 16px;
            }
        }

        .ant-collapse {
            border: 0;
            border-bottom: 1px solid #D7DDE1;
            border-radius: 0px;

            .ant-collapse-header {
                background: white !important;
            }
        }

        .ant-collapse-content-active {
            border-top: 0;
        }
        .buttonContainer {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            position: fixed;
            width: 100%;
            bottom: 0px;
            background: white;
            margin-left: -80px;

      .buttonContentContainer {
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;

        .saveAndExitButton {
          position: absolute;
          right: 40px;
          margin-bottom: 15px;
        }

        .nextAndPreviousContainer {
          display: flex;
          gap: 20px;
          margin-bottom: 15px;
        }
      }
    }
    }
`;


export default StyledDashboardPage;
