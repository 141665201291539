import React, { useEffect, useState } from "react";
import { Form, Input, Checkbox } from "antd";
import clsx from "clsx";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import StyledText from "../../components/StyledText";
import { getCreatePassword } from '../../redux/actions';
import { setLocalStorageItem, passwordValidator } from './../../utils/utilities';
import { createPasswordSelector } from '../../redux/selectors'
import {
    useAppDispatch,
    useAppSelector,
} from "../../redux/store";
import Button from "../../components/Button";
import LinkComponent from "../../components/LinkComponent";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const CreatePassword = ({ className }) => {
    const baseClassName = clsx("createPasswordPage", className);
    const intl = useIntl();
    const [form] = Form.useForm();
    const location = useLocation();
    const [loading, setLoading] = useState();
    const createPassword = useAppSelector(createPasswordSelector);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email") || queryParams.get("username");
    const entity = queryParams.get("entity");
    const oldPassword = queryParams.get("password");

    useEffect(() => {
        if (createPassword) {
            setLoading(false);
            if (entity === 'company') {
                navigate(`/company/my-profile/edit`);
            } else {
                navigate(`/impact-maker/my-profile/edit`);
            }
        }
    }, [createPassword])

    const onFinish = (values) => {
        const data = { email, oldPassword, entity, newPassword: values.password };
        setLoading(true);
        dispatch(getCreatePassword(data))
        setLocalStorageItem('forgot_password', true);
    };

    return (
        <div className={baseClassName}>
            <div className="containerBox">
                <StyledText as="p" variant="H1" className="title">
                    {intl.formatMessage({ id: "create_password" })}
                </StyledText>
            </div>
            <div className="changePassword">
                <Form
                    {...formItemLayout}
                    form={form}
                    className="formContainer"
                    name="register"
                    onFinish={onFinish}
                    initialValues={{
                        residence: ["zhejiang", "hangzhou", "xihu"],
                        prefix: "86",
                    }}
                    scrollToFirstError
                >
                    <Form.Item
                        name="password"
                        validateTrigger="onBlur"
                        className="formItem"
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({ id: "please_enter_password" }),
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    const validationMessage = passwordValidator(value);
                                    if (!validationMessage) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(validationMessage));
                                },
                            }),
                        ]}
                        hasFeedback
                    >
                        <Input.Password placeholder={intl.formatMessage({ id: 'password' })} />
                    </Form.Item>
                    <Form.Item
                        name="confirm"
                        dependencies={["password"]}
                        className="formItem"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({ id: "please_confirm_your_password" }),
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue("password") === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(`${intl.formatMessage({ id: 'the_new_password_that_you_entered_do_not_match' })}`)
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder={intl.formatMessage({ id: 'confirm_password' })} />
                    </Form.Item>
                    <StyledText as="p" variant="B3" className="title">
                        {intl.formatMessage({ id: 'password_must_include' })}
                    </StyledText>
                    <StyledText as="p" variant="B3" className="title">
                        {intl.formatMessage({ id: 'more_than_eight_characters' })}
                    </StyledText>
                    <StyledText as="p" variant="B3" className="title">
                        {intl.formatMessage({ id: 'an_upper_and_lower_case_character' })}
                    </StyledText>
                    <StyledText as="p" variant="B3" className="title">
                        {intl.formatMessage({ id: 'a_number' })}
                    </StyledText>
                    <StyledText as="p" variant="B3" className="title">
                        {intl.formatMessage({ id: 'a_special_character' })}
                    </StyledText>
                    <div className="checkBoxWrapper">
                        <Form.Item
                            valuePropName="checked"
                            name="user_terms"
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({ id: 'please_accept_the_user_terms' }),
                                },
                            ]}
                        >
                            <Checkbox />
                        </Form.Item>
                        <LinkComponent href="https://bizgiveworld.com/user-terms/">
                            <StyledText as="p" variant="B3" className='terms'>
                                {intl.formatMessage({ id: "i_accept_the" })}
                                <StyledText as="span" variant="B3"
                                    href="https://bizgiveworld.com/user-terms/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className='companyLinks'
                                >
                                    {intl.formatMessage({ id: "user_terms" })}
                                </StyledText>
                            </StyledText>
                        </LinkComponent>
                    </div>

                    <div className="submitButton">
                        <Button variant="primary" htmlType="submit" className="passwordBtn" loading={loading}>
                            {intl.formatMessage({ id: "create_password" })}
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

const StyledCreatePassword = styled(CreatePassword)`
  &.createPasswordPage {
    background: white;
    min-height: 100vh;
    display: flex;
    padding: 20px;
    flex-direction: column;
    .containerBox {
      text-align: center;
      margin-top: 70px;
      align-items: center;
     display: flex;
     justify-content: center;
    }
    .changePassword {
      border-radius: 6px;
      display: flex;
      justify-content: center;
      padding: 40px 20px;
      margin: 0 auto;
      width: 450px;
      height: max-content;
      margin-top: 30px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
        .ant-form-item-control-input{
          width: 250px;
        }
        .checkBoxWrapper{
            .ant-form-item-control-input{
                width: 165px;
        }
        }
        .submitButton{
          display: flex;
          justify-content: center;
          .passwordBtn {
           margin-top: 5%;
        }
        }
      .paraStyles {
        font-size: 11px;
        color: #00aa9d;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .formContainer{
        max-width: 600;
        justify-content: center;
        flex-direction: column;
        .formItem{
          width: 200px !important;
        }
      }
    }
    .checkBoxWrapper{
            display: flex;
            align-items: center;
            gap: 10px;
            margin-top: 10px;
            position: relative;
            .terms{
                position: absolute;
                left: 25px;
                top: 5px;
            }
            .ant-form-item{
                margin-bottom: 0;
            }       
            .companyLinks {
              margin-left: 5px;
              text-decoration: underline;
              color: #04ac9c;
            }
          }
  }
`;

export default StyledCreatePassword;
