import React from 'react';
import { Avatar } from 'antd';
import styled from "styled-components";
import clsx from "clsx";
import StyledText from "../../../components/StyledText";
import volunteer from "../../../assets/volunteer.svg";

const Volunteering = ({ className, title, volunteerUse }) => {
    const baseClassName = clsx("volunteering", className);
    return (
        <div className={baseClassName}>
            <div className="supportContainer volunteeringContainer">
                <div className="titleContainer">
                    <div>
                        <Avatar src={volunteer} />
                    </div>
                    <div>
                        <StyledText as="p" variant="H3">
                            {title}
                        </StyledText>
                    </div>
                </div>
                <ul>
                    {volunteerUse.map((el) => (
                        <li key={el?.volunteerUnit}>
                            <StyledText as="span" variant="B3">
                                <span className="outputNumber">{el?.volunteerUnit}</span> for {el?.volunteerValue}
                            </StyledText>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

const StyledVolunteering = styled(Volunteering)`
&.volunteering{
    .supportContainer {
    padding: 16px;
    width: max-content;
    width: 35vw;
    .divider{
        height: 100%;
    margin-inline: 10px;
    }
    &.volunteeringContainer {
          background: transparent linear-gradient(223deg, #ffffff 0%, #8bdaff 100%) 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 16px #0000000d;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
  }
  .outputNumber{
        background: #dee3ed;
        border-radius: 5px;
        padding: 1px 8px;
        border: 1px solid grey; 
    }
  .titleContainer {
          display: flex;
          gap: 2px;
          align-items: center;
        }
  .additionalFunding{
        background: white !important;
        padding: 20px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
  .expenditureValues{
        background: #5DB76A;
        border-radius: 5px;
        padding: 1px 8px;
        border: 1px solid #5DB76A;
    }
  .flexBox{
        display: flex;
        align-items: flex-start;
        gap: 20px;
        &.justifyContent{
            justify-content: flex-start;
        }
        .flexBoxChild{
            display: flex;
            flex-direction: column;
            gap: 10px;
            .flexColumn{
                display: flex;
                flex-direction: column;
                .noBreak{
                    white-space: nowrap;
                }
            }
        }
    }
}
`
export default StyledVolunteering;
