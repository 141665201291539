import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { Divider, Form, Spin, Input, Upload } from "antd";
import styled from "styled-components";
import { getContent, toggleSpinner, editContent, setEditCharityProfile, setCreateCharityProfile, getOpportunity } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Config from '../../config';
import axios from '../../utils/axios';
import Button from "../../components/Button";
import { getLocalStorageItem, uploadFile } from "../../utils/utilities";
import {
    toggleSpinnerSelector,
    opportunityDataSelector,
    savedContentListSelector,
    savedContentData
} from "../../redux/selectors";
import grant from "../../assets/grant.svg";
import GrantDetails from "../charityCreate/charityProgramReview/Grants"
import VolunteeringDetails from "../charityCreate/charityProgramReview/Volunteer";
import InKindDetails from "../charityCreate/charityProgramReview/InKind";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

const { TextArea } = Input;

const CharityInterimCreateReport = ({ className }) => {
    const baseClassName = clsx("charityInterimCreateReport", className);
    const intl = useIntl();
    const [pictures, setPictures] = useState([]);
    const [mediaDescriptions, setMediaDescriptions] = useState([]);
    const [savedClicked, setSavedClicked] = useState(false);
    const opportunityData = useAppSelector(opportunityDataSelector) || {}
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const { ID } = useParams();
    const { ID: contentId,
        additionalComment,
        interimChanges,
        interimDateChanges,
        gallery
    } = useAppSelector(savedContentData) || {}
    const { opportunityID } = JSON.parse(getLocalStorageItem('contentData') || '{}');
    const { opportunity:
        { volunteerUse,
            inKindUse,
            totalCashRequested,
            wholeProjectFunding,
            grantBudgetPercentage = 0,
            donationUse,
            additionalFundsSource,
            fundingSecured,
            oppFullBudgetNotRequired,
        } = {} }
        = opportunityData || {};

    const { message } = useAppSelector(savedContentListSelector) || {};

    useEffect(() => {
        if (contentId) {
            form.setFieldsValue({
                additionalComment,
                interimChanges,
                interimDateChanges,

            });

            if (gallery) {
                setPictures(gallery);
                const initialDescriptions = gallery.reduce((acc, pic) => {
                    acc[pic.uid] = pic.mediaDescription || '';
                    return acc;
                }, {});
                setMediaDescriptions(initialDescriptions);
            }
        }
    }, [contentId]);

    useEffect(() => {
        if (message && !savedClicked) {
            dispatch(setEditCharityProfile(''))
            dispatch(setCreateCharityProfile(''))
            dispatch(toggleSpinner(false));
            navigate(`/impact-maker/content/interim/preview/${ID}`);
        }
    }, [message])

    useEffect(() => {
        if (opportunityID) {
            document?.documentElement?.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getContent({
                contentID: ID
            }));
            dispatch(getOpportunity(opportunityID));
        }
    }, [opportunityID]);

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const handleProceed = (e) => {
        const formData = form.getFieldsValue();
        const data = {
            contentId: ID,
            additionalComment: formData?.additionalComment,
            interimChanges: formData?.interimChanges,
            interimDateChanges: formData?.interimDateChanges,
            gallery: pictures?.map(({ uid, name, url, mediaDescription, thumbUrl, key }) => ({ uid, name, url, mediaDescription, thumbUrl, key })),
            action: "Draft"
        }
        dispatch(editContent(data))
    }

    const onChange = ({ fileList: newFileList }) => {
        setPictures(prevPictures => {
            const updatedPictures = newFileList.map(newFile => {
                const existingPicture = prevPictures.find(pic => pic.uid === newFile.uid);
                return existingPicture ? { ...existingPicture, ...newFile } : newFile;
            });
            return updatedPictures;
        });
    };

    const processFileUpload = async ({ onSuccess, onError, file, onProgress }) => {
        try {
            let presignedPostData = await getPresignedPostData(file);
            presignedPostData = await presignedPostData.data;
            await uploadFileToS3(presignedPostData, file, onProgress);
            onSuccess(null, file);
            setPictures(prevPictures =>
                prevPictures.map(pic =>
                    pic.uid === file.uid ? { ...pic, ...presignedPostData.fields } : pic
                )
            );
        } catch (e) {
            message.error(`File upload failed. ${e.message || ''}`);
            onError(e);
        }
    };

    const getPresignedPostData = async (selectedFile) => new Promise((resolve, reject) => {
        const fileName = selectedFile.name.replace(
            /(?:\.(?![^.]+$)|[^\w.])+/g,
            ''
        );
        const url = `${Config.dev.url.companyLogoUploadUrl}?fileName=${fileName}`;
        axios.get(url, (res, err) => {
            if (res && res.status === 200) {
                resolve(res.data);
            } else {
                reject(err.response);
            }
        });
    });

    const uploadFileToS3 = async (presignedPostData, file, onProgress) =>
        new Promise((resolve, reject) => {
            const formData = new FormData();
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            Object.keys(presignedPostData.fields).forEach((key) => {
                formData.append(key, presignedPostData.fields[key]);
            });
            formData.append("file", file);
            uploadFile(
                presignedPostData.url,
                formData,
                (res, err) => {
                    if (res && res.status === 204) {
                        resolve(res.data);
                    } else {
                        reject(err.response);
                    }
                },
                config
            );
        });

    const onPreview = async (file) => {
        let src = file.url;
        if (!src && file?.originFileObj instanceof Blob) {
            src = await new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(new Error('Failed to read file'));
            });
        }

        if (src) {
            const image = new Image();
            image.src = src;
            const imgWindow = window.open(src);
            imgWindow?.document.write(image.outerHTML);
        } else {
            console.error('Failed to preview image: Source is invalid or unavailable.');
        }
    };

    const onDelete = (file) => {
        setPictures((prevPictures) => prevPictures.filter(picture => picture.uid !== file.uid));
    }

    const onRemove = async (file) => {
        const { deleteURL } = Config.dev.url;
        const data = { bucketName: 'bg-uploaded-media', fileName: file.key };
        axios.put(deleteURL, () => onDelete(file), data, true);
    };

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
        }, 300);
    }

    const handleDescriptionChange = (key) => (e) => {
        const newDescriptions = { ...mediaDescriptions, [key]: e.target.value };
        setMediaDescriptions(newDescriptions);
        const updatedPictures = pictures.map((pic) =>
            pic.uid === key ? { ...pic, mediaDescription: e.target.value } : pic
        );
        setPictures(updatedPictures);
    };

    const handlePrevious = () => {
        navigate(`/impact-maker/content/interim/start/${ID}`)
    }

    return (
        <div className={baseClassName}>
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <div className='contentContainer'>
                <div>
                    <StyledText as="p" variant="H1" className='mainTitle'>
                        {intl.formatMessage({ id: "interim_update" })}
                    </StyledText>
                    <Form
                        {...formItemLayout}
                        labelAlign="left"
                        onFinish={handleProceed}
                        onFinishFailed={onFinishFailed}
                        form={form}
                        className="formContainer"
                        scrollToFirstError
                    >
                        <div className="mainContentContainer">
                            <div className="contentContainer">
                                <StyledText as="p" variant="B3d">
                                    {intl.formatMessage({ id: "below_is_the_support_you_requested_from_the_company_in_your_application" })}
                                </StyledText>
                                <GrantDetails
                                    grantImage={grant}
                                    totalCashRequested={totalCashRequested}
                                    wholeProjectFunding={wholeProjectFunding}
                                    grantBudgetPercentage={grantBudgetPercentage}
                                    donationUse={donationUse}
                                    additionalFundsSource={additionalFundsSource}
                                    fundingSecured={fundingSecured}
                                    oppFullBudgetNotRequired={oppFullBudgetNotRequired}
                                    intl={intl}
                                />
                                {volunteerUse?.length > 0 && <VolunteeringDetails
                                    title={intl.formatMessage({ id: "volunteering" })}
                                    volunteerUse={volunteerUse}
                                />}
                                {inKindUse?.length > 0 && <InKindDetails
                                    title={intl.formatMessage({ id: "inkind" })}
                                    inKindUse={inKindUse}
                                />}
                                <StyledText as="p" variant="B3d">
                                    {intl.formatMessage({ id: "how_have_you_used_the_support_provided_so_far" })}
                                </StyledText>
                                <Form.Item
                                    name="additionalComment"
                                    className="formItem"
                                    rules={[
                                        {
                                            whitespace: true,
                                            required: true,
                                            message: intl.formatMessage({ id: "enter" }),
                                        },
                                    ]}
                                >
                                    <TextArea
                                        name="additionalComment"
                                        rows={2}
                                    />
                                </Form.Item>
                                <StyledText as="p" variant="B3d">
                                    {intl.formatMessage({ id: "are_there_any_changes_to_your_planned_expenditure_and_if_so_why" })}
                                </StyledText>
                                <Form.Item
                                    name="interimChanges"
                                    className="formItem"
                                    rules={[
                                        {
                                            whitespace: true,
                                            required: true,
                                            message: intl.formatMessage({ id: "enter" }),
                                        },
                                    ]}
                                >
                                    <TextArea
                                        name="interimChanges"
                                        rows={2}
                                    />
                                </Form.Item>
                                <StyledText as="p" variant="B3d">
                                    {intl.formatMessage({ id: "are_there_any_changes_to_your_estimated_completion_date_and_if_so_why" })}
                                </StyledText>
                                <StyledText as="p" variant="B3">
                                    {`${intl.formatMessage({ id: "please_email_bizGive" })} `}
                                    <StyledText as="span" variant="B3">
                                        <a href="https://support@bizgiveworld.com">(support@bizgiveworld.com)</a>
                                    </StyledText> <StyledText as="span" variant="B3">
                                        {` ${intl.formatMessage({ id: "if_there_is_a_substantial_change_to_your_completion_date_and_we_will_ensure_the_deadline_for_your_final_report_is_update" })}`}
                                    </StyledText>
                                </StyledText>
                                <Form.Item
                                    name="interimDateChanges"
                                    className="formItem"
                                    rules={[
                                        {
                                            whitespace: true,
                                            required: true,
                                            message: intl.formatMessage({ id: "enter" }),
                                        },
                                    ]}
                                >
                                    <TextArea
                                        name="interimDateChanges"
                                        rows={2}
                                    />
                                </Form.Item>
                                <StyledText as="span" variant="B3d">
                                    {intl.formatMessage({ id: "share_media" })}
                                    <StyledText as="span" variant="B3a">
                                        {` (${intl.formatMessage({ id: "optional" })})`}
                                    </StyledText>
                                </StyledText>
                                <StyledText as="p" variant="B3">
                                    {intl.formatMessage({ id: "please_upload_media_to_bring_your_project_to_life_this_significantly_increases_the_likelihood_of_the_company_sharing_your_content" })}
                                </StyledText>
                                <Form.Item className='uploadWrapper'>
                                    <Upload
                                        listType="picture-card"
                                        onChange={onChange}
                                        fileList={pictures}
                                        onPreview={onPreview}
                                        onRemove={onRemove}
                                        customRequest={({
                                            onSuccess, onError, file, onProgress
                                        }) => processFileUpload({
                                            onSuccess, onError, file, onProgress
                                        })}
                                        multiple
                                    >
                                        {`+ ${intl.formatMessage({ id: "upload" })}`}
                                    </Upload>
                                    <div className='uploadInputWrapper'>
                                        {pictures.map((file) => (
                                            <Form.Item key={file.uid}>
                                                <Input
                                                    placeholder={intl.formatMessage({ id: "enter_caption" })}
                                                    value={mediaDescriptions[file.uid] || ''}
                                                    onChange={handleDescriptionChange(file.uid)}
                                                />
                                            </Form.Item>
                                        ))}
                                    </div>
                                </Form.Item>
                            </div>
                            <div className="buttonContainer">
                                <Divider />
                                <div className="buttonContentContainer">
                                    <div className="nextAndPreviousContainer">
                                        <Button variant="secondary" type="button" onClick={() => handlePrevious()}>
                                            {intl.formatMessage({ id: "previous" })}
                                        </Button>
                                        <Button variant="primary" htmlType="submit">
                                            {intl.formatMessage({ id: "next" })}
                                        </Button>
                                    </div>
                                    <div className="saveAndExitButton">
                                        <Button variant="secondary" htmlType="button" onClick={() => handleSaveAndExit()}>
                                            {intl.formatMessage({ id: "save_n_exit" })}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>

                </div>
            </div>
        </div>
    )
}

const StyledCharityInterimCreateReport = styled(CharityInterimCreateReport)`
  &.charityInterimCreateReport {
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;

    .uploadWrapper .ant-form-item-control-input-content {
      display: flex !important;
      width: 100%;

      .ant-upload-list {
        display: flex;
        flex-direction: column !important;
      }

      .uploadInputWrapper {
        gap: 56px;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        margin-left: 30px;
        width: 100%;
      }
    }

    .mainTitle {
      margin-bottom: 20px;
    }

    .formItem .ant-form-item-explain-error {
      margin-top: -20px !important;
    }

    .contentContainer {
      display: flex;
      flex-direction: column;
      gap: 12px;
      height: 250vh;

      .formContainer {
        display: flex;
        gap: 12px;
        flex-direction: column;
      }
    }

    .overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1000;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.45);
    }

    .spinner {
      z-index: 2000;
      margin: auto;
      left: 50%;
      right: 50%;
      position: absolute;
      bottom: 50%;
    }

    .radioGroup {
      display: flex;
      flex-direction: column;
    }

    .formItemTextField {
      .ant-form-item-control-input {
        width: 34.5vw !important;
      }
    }

    .formItemTextFieldPart {
      .ant-form-item-control-input {
        width: 16vw !important;
      }
    }

    .helperText {
      color: #5271FF !important;
      font-weight: bold !important;
      margin-bottom: 0px !important;
    }

    .ant-form-item {
      margin-bottom: 10px;
    }

    .formItemInput {
      width: 300px;
    }

    .buttonContainer {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      position: fixed;
      width: 100%;
      bottom: 0px;
      background: white;
      margin-left: -80px;

      .buttonContentContainer {
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;

        .saveAndExitButton {
          position: absolute;
          right: 40px;
          margin-bottom: 15px;
        }

        .nextAndPreviousContainer {
          display: flex;
          gap: 20px;
          margin-bottom: 15px;
        }
      }
    }
  }
`;

export default StyledCharityInterimCreateReport;
