import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Helmet as HelmetAsync } from "react-helmet-async";
import {
    EnvironmentFilled,
    StarFilled,
    CalendarFilled,
    InfoCircleOutlined
} from "@ant-design/icons";
import clsx from "clsx";
import { Tabs, Layout, Divider, Timeline, Form, message, Radio, Input, Checkbox } from 'antd';
import { useIntl } from 'react-intl';
import Feedback from "./CompanyFeedback";
import styled from "styled-components";
import {
    useAppSelector,
    useAppDispatch
} from "../../../redux/store";
import {
    programDataSelector,
    opportunityDataSelector,
    profileSelector,
    interactionSelector,
    addInteractionSelector,
    deleteProgramSelector,
    getConversationSelector,
    declineConversationSelector
} from "../../../redux/selectors";
import CompanyProgramReview from './CompanyProgramReview';
import MapBoxComponent from "../../../components/MapBoxComponent";
import FullScreenWrapper from '../../../components/FullscreenWrapper';
import { addInteraction, getInteraction, setDeleteProgram, getProfileDataCompany, sendDeclineConversation, getOpportunity, getIndicatorAnswers, setSendConversation } from '../../../redux/actions';
import ProgStartData from '../../charityCreate/bizGiveThemeImageData';
import { getSecuredUrl } from "../../../utils/utilities";
import DateFormatter from "../../../components/DateFormatter";
import StyledText from "../../../components/StyledText";
import Button from '../../../components/Button';
import CustomModal from "../../../components/Modal";
import CompanyApproval from "./CompanyApproval";
import CompanyInternalReview from "./CompanyInternalReview";
import LinkComponent from "../../../components/LinkComponent";
const { TextArea } = Input;

const getUpdateDueDateMessage = ({
    updateDueDateRelativeDays,
    updateDueDate,
    currentBudgetStartDate,
    currentBudgetEndDate
}) => {
    if (updateDueDateRelativeDays) {
        if (currentBudgetStartDate && currentBudgetEndDate) {
            return `Every ${updateDueDateRelativeDays} days during the project running dates`;
        } else {
            return `${updateDueDateRelativeDays} days after the project start date`;
        }
    } else if (updateDueDate) {
        return updateDueDate.join(', ');
    } else {
        return 'Not Required';
    }
}

const ProgramReviewHomePage = ({ className }) => {
    const baseClassName = clsx("dashboardPage", className);
    const [selectedRadio, setSelectedRadio] = useState(null);
    const [selectedBizGiveImage, setSelectedBizGiveImage] = useState({});
    const [logoS3URL, setLogoS3URL] = useState('');
    const [declineApplicationModal, setDeclineApplicationModal] = useState(false);
    const [preApproveApplicationModal, setPreApproveApplicationModal] = useState(false);
    const [approveConfirmationModal, setApproveConfirmationModal] = useState(false);
    const addInteractionData = useAppSelector(addInteractionSelector);
    const deleteProgramData = useAppSelector(deleteProgramSelector);
    const conversations = useAppSelector(getConversationSelector);
    const [logoImageURL, setLogoImageURL] = useState('');
    const programData = useAppSelector(programDataSelector);
    const interactions = useAppSelector(interactionSelector);
    const [activeTab, setActiveTab] = useState('application');
    const declineConversationData = useAppSelector(declineConversationSelector);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const intl = useIntl();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { company: { logoS3URL: logoImage } = {} } = useAppSelector(profileSelector) || {};
    const opportunityData = useAppSelector(opportunityDataSelector) || {};
    const [isFullscreen, setIsFullscreen] = useState(false);
    const { programID, opportunityID: opportunityId } = useParams();

    const { status, approvalStepsDone } = interactions?.[0] || {};
    const { data, users } = conversations || {}

    const {
        currentBudgetStartDate,
        currentBudgetEndDate,
        programmeLocation,
        updateDueDateRelativeDays,
        outcomeDueDateRelativeDays,
        programName
    } = programData || {};
    const { opportunity:
        { startDate,
            endDate,
            coverImage,
            donationDeadLineDate,
            updateDueDate,
            outComeDate,
            opportunityName,
            coverImageType,
            totalCashRequested,
            cashTargetCurrencyCode,
            opportunityOutsidePolygonReason,
            opportunityLocation2: {
                features
            } = {},
        } = {} }
        = opportunityData || {};


    const items = [
        {
            key: "application",
            label: <StyledText as="p" variant="B3" className="title">
                {intl.formatMessage({ id: "application" })}
            </StyledText>,
            children: <CompanyProgramReview />,
        },
        {
            key: "entity",
            label: <StyledText as="p" variant="B3" className="title">
                {intl.formatMessage({ id: "entity" })}
            </StyledText>,
            children: <></>,
        },
        {
            key: "feedback",
            label: <StyledText as="p" variant="B3" className="title">
                {intl.formatMessage({ id: "feedback" })}
            </StyledText>,
            children: <Feedback data={data} users={users} status={status} />,
        },
        {
            key: "internal_review",
            label: <StyledText as="p" variant="B3" className="title">
                {intl.formatMessage({ id: "internal_review" })}
            </StyledText>,
            children: <CompanyInternalReview />,
        },
        (approvalStepsDone === "Y" || approvalStepsDone === 'N') && {
            key: "conditional_internal_review",
            label: <StyledText as="p" variant="B3" className="title">
                {intl.formatMessage({ id: "approval" })}
            </StyledText>,
            children: <CompanyApproval />,
        },
    ];

    const setLogoImage = async fileKey => {
        const securedURI = await getSecuredUrl(fileKey);
        const securedURL = URL.createObjectURL(securedURI);
        setLogoS3URL(securedURL);
        return fileKey ? securedURL : '';
    };

    const setLogoBannerImage = async fileKey => {
        const securedURI = await getSecuredUrl(fileKey);
        const securedURL = URL.createObjectURL(securedURI);
        setLogoImageURL(securedURL);
        return fileKey ? securedURL : '';
    };

    useEffect(() => {
        if (declineConversationData) {
            setSendConversation('')
            setActiveTab('feedback')
        }
    }, [declineConversationData]);

    useEffect(() => {
        if (addInteractionData) {
            dispatch(getInteraction({
                "filterBy": {
                    programID,
                    opportunityID: opportunityId
                }
            }))
        }
    }, [addInteractionData])

    useEffect(() => {
        if (deleteProgramData) {
            message.success("Success - application deleted")
            dispatch(setDeleteProgram(''))
            navigate("/impact-maker/applications/actions")
        }
    }, [deleteProgramData])

    useEffect(() => {
        if (opportunityName) {
            dispatch(getProfileDataCompany())
            dispatch(getIndicatorAnswers({
                programID,
                opportunityID: opportunityId
            }))
            dispatch(getOpportunity(opportunityId));
            form.setFieldsValue({
                opportunityName: opportunityName,
                coverImageType: coverImageType,
                coverImage: coverImage,
            });

            if (coverImage) {
                const selectedImage = ProgStartData.bizGiveThemeImageData.find(el => el.id === coverImage);
                if (coverImageType === '2') {
                    setLogoImage(coverImage).then(savedCover => setSelectedBizGiveImage(savedCover));
                } else {
                    setSelectedBizGiveImage(selectedImage);
                }
                ProgStartData.bizGiveThemeImageData.forEach(item => {
                    if (item.id === coverImage) item.checked = true;
                });
            }
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [opportunityName]);

    useEffect(() => {
        if (logoImage) {
            setLogoBannerImage(logoImage)
        }
    }, [logoImage])

    const renderCoverImage = () => {
        if (selectedBizGiveImage?.path && typeof selectedBizGiveImage?.path === 'string') return <img alt="covercc" className="cameraIcon" src={selectedBizGiveImage.path} />;
        if (logoS3URL && typeof logoS3URL === 'string') return <img alt="coverdd" className="cameraIcon" src={logoS3URL} />;
        return '';
    };

    const validateSelection = (_, value) => {
        if (!selectedRadio) {
            return Promise.reject(new Error(intl.formatMessage({ id: 'please_select_an_decline_option_or_write_a_message' })));
        }
        if (selectedRadio && selectedCheckboxes.length === 0) {
            return Promise.reject(new Error(intl.formatMessage({ id: 'please_select_at_least_one_checkbox' })));
        }
        return Promise.resolve();
    };

    const updateDueDateMessage = getUpdateDueDateMessage({
        updateDueDateRelativeDays,
        updateDueDate,
        currentBudgetStartDate,
        currentBudgetEndDate
    });

    const handlePrevious = () => {
        if (["Applied"].includes(status)) {
            navigate(`/company/applications/actions`)
        } else if (["InEdit"].includes(status)) {
            navigate(`/company/applications/pending`)
        } else if (["Approved"].includes(status)) {
            navigate(`/company/applications/approved`)
        } else if (["PreApproved"].includes(status)) {
            navigate(`/company/applications/pre-approved`)
        } else if (["Declined"].includes(status)) {
            navigate(`/company/applications/declined`)
        } else {
            navigate(`/company/applications/actions`)
        }
    };

    const handleRadioChange = (e) => {
        setSelectedRadio(e.target.value);
        setSelectedCheckboxes([]);
    };

    const handleCheckboxChange = (checkedValues) => {
        setSelectedCheckboxes(checkedValues);
        form.validateFields(['radioBox']);
    };

    const handleDecline = async () => {
        try {
            await form.validateFields();
            const allValues = form.getFieldsValue();
            const formattedCheckboxValues = selectedCheckboxes
                .map(value => `<br />- ${value}`)
                .join("");

            const message = `${formattedCheckboxValues}<br /><br />${allValues?.declineTextField}<br />`;
            const data = {
                programID,
                opportunityID: opportunityId,
                messageStatus: `Application Decline: ${selectedRadio}`,
                message
            }
            const data1 = {
                programID,
                opportunityID: opportunityId,
                action: "declineOpportunity"
            }
            dispatch(sendDeclineConversation(data))
            dispatch(addInteraction(data1))
            setDeclineApplicationModal(false);
        } catch (errorInfo) {
            console.log('Validation Failed:', errorInfo);
        }

    }


    return (
        <div className={baseClassName}>
            <HelmetAsync>
                <title>Programme Review Page</title>
            </HelmetAsync>
            <div className='leftContainer'>
                <div >
                    <Layout className="bannerImageContainer">
                        {renderCoverImage()}
                    </Layout>
                    <div className='companyTitleContainer'>
                        <div className='imageContainer'>{logoImageURL && <img src={logoImageURL} alt="logoImage" />}</div>
                        <div>
                            <StyledText as="p" variant="H1c" className="title">
                                {opportunityName}
                            </StyledText>
                            <LinkComponent href={`${window.location.origin}/company/programmes/review/${programID}`}><StyledText as="p" variant="H2" className="title">
                                {`${intl.formatMessage({ id: 'application_to' })}: `}
                                <StyledText as="span" variant="B3b">
                                    {programName}
                                </StyledText>
                            </StyledText>
                            </LinkComponent>
                        </div>
                        {status?.toLowerCase() === "preapproved" && <div className="flexColumn">
                            <Button variant="primary" disabled={approvalStepsDone === 'N'} onClick={() => {
                                setApproveConfirmationModal(true)
                            }}>{intl.formatMessage({ id: 'approve' })}
                            </Button>
                            <Button variant="danger" onClick={() => {
                                setDeclineApplicationModal(true)
                            }}>{intl.formatMessage({ id: 'decline' })}</Button>
                            <Button variant="primary" onClick={() => {
                                setPreApproveApplicationModal(true)
                            }}>{intl.formatMessage({ id: 'withdraw_pre_approval' })}
                            </Button>
                        </div>}
                        {status?.toLowerCase() === "applied" && <div className="flexColumn">
                            <Button variant="primary" onClick={() => {
                                setPreApproveApplicationModal(true)
                            }}>{intl.formatMessage({ id: 'pre_approve' })}</Button>
                            <Button variant="danger" onClick={() => {
                                setDeclineApplicationModal(true)
                            }}>{intl.formatMessage({ id: 'decline' })}
                            </Button>
                        </div>}
                    </div>
                </div>
                <Button
                    className='goBackButton'
                    variant="secondary"
                    type="button"
                    onClick={() => handlePrevious()}>
                    <StyledText as="p" variant="B3c">
                        {`< ${intl.formatMessage({ id: "go_back" })}`}
                    </StyledText>
                </Button>
                <Tabs activeKey={activeTab} items={items} onChange={(tab) => setActiveTab(tab)} />
            </div>
            <div className="rightSideContainer">
                <FullScreenWrapper isFullscreen={isFullscreen}
                    setIsFullscreen={setIsFullscreen}>
                    <MapBoxComponent
                        zoom={4}
                        defaultStyle="MonoCrome"
                        id="submission"
                        markers={features}
                        geoJsonData={programmeLocation}
                        hideStyles
                        isFullscreen={isFullscreen}
                        hideZoomControls
                        styles="mapbox://styles/mapbox/outdoors-v12"
                    />
                </FullScreenWrapper>
                <div className="rightContainer">
                    <div className="locationContainer subContainer">
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "project_location" })}
                        </StyledText>
                        {features?.map((item) => <div key={item} className="locationIcon">
                            <EnvironmentFilled />
                            <StyledText as="p" variant="B3" key={item} className="city">
                                {item?.place_name === 'anyLocation'
                                    ? intl.formatMessage({ id: 'any_location' })
                                    : (item.preferred_name || item?.place_name)}
                            </StyledText>
                        </div>)}
                        {opportunityOutsidePolygonReason && <><div className="locationIcon">
                            <InfoCircleOutlined />
                            <StyledText as="p" variant="B3d" className="city">
                                {intl.formatMessage({ id: "outside_of_impact_area" })}
                            </StyledText>
                        </div>
                            <StyledText as="p" variant="B3" className="opportunityOutsidePolygonReason">
                                {opportunityOutsidePolygonReason}
                            </StyledText>
                        </>}
                    </div>
                    <Divider />
                    <StyledText as="p" variant="H2" className="subContainer">
                        {intl.formatMessage({ id: "project_timelines" })}
                    </StyledText>
                    <Timeline
                        items={[
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "project_running_dates" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /> <StyledText as="p" variant="B3c">
                                                <>
                                                    <DateFormatter dateValue={startDate?.trim()} /> - <DateFormatter dateValue={endDate?.trim()} />
                                                </>
                                            </StyledText></div>
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "donations_required_before" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /><StyledText as="p" variant="B3c">
                                                <DateFormatter dateValue={donationDeadLineDate?.trim()} />
                                            </StyledText></div>
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "interim_updates" })}
                                    </StyledText>
                                        {Array.isArray(updateDueDate) && updateDueDate?.map((el) => <div key={el} className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /><StyledText as="p" variant="B3c">
                                                {el ? <DateFormatter dateValue={el} /> : updateDueDateMessage}
                                            </StyledText></div>)}
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "final_report" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /><StyledText as="p" variant="B3c">
                                                {<DateFormatter dateValue={outComeDate?.trim()} /> || `${outcomeDueDateRelativeDays} after the project end date`}
                                            </StyledText></div>
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
            <CustomModal
                title={intl.formatMessage({ id: 'confirm_decline' })}
                open={declineApplicationModal}
                onOk={() => setDeclineApplicationModal(false)}
                onCancel={() => setDeclineApplicationModal(false)}
                closable
                footer={null}
                className="companyActionModal"
            >
                <Form
                    labelAlign="left"
                    form={form}
                    className="formContainer"
                    scrollToFirstError
                >
                    <StyledText as="p" variant="B3d" className="title">
                        {intl.formatMessage({ id: 'please_explain_your_decision_to_the_applicant' })}
                    </StyledText>
                    <Form.Item
                        name="radioBox"
                        rules={[{ validator: validateSelection }]}
                        valuePropName='checked'
                    >
                        <Radio.Group className="radioGroup" onChange={handleRadioChange}>
                            <Radio className="radioCompany" value={intl.formatMessage({ id: "application_related" })} checked={selectedRadio === intl.formatMessage({ id: "application_related" })}>
                                <StyledText as="p" variant="B3" style={{ marginLeft: '5px' }}>
                                    {intl.formatMessage({ id: "application_related" })}
                                </StyledText>
                                {(selectedRadio === intl.formatMessage({ id: "application_related" })) && (
                                    <Checkbox.Group onChange={handleCheckboxChange}>
                                        <Checkbox value={intl.formatMessage({ id: "there_are_items_we_cannot_fund" })}>{intl.formatMessage({ id: "there_are_items_we_cannot_fund" })}</Checkbox>
                                        <Checkbox value={intl.formatMessage({ id: "the_impact_of_your_project_is_not_clear_enough" })}>{intl.formatMessage({ id: "the_impact_of_your_project_is_not_clear_enough" })}</Checkbox>
                                        <Checkbox value={intl.formatMessage({ id: "the_impact_of_your_project_is_lower_relative_to_other_applications" })}>{intl.formatMessage({ id: "the_impact_of_your_project_is_lower_relative_to_other_applications" })}</Checkbox>
                                    </Checkbox.Group>
                                )}
                            </Radio>
                            <Radio className="radioCompany" value={intl.formatMessage({ id: "entity_related" })} checked={selectedRadio === intl.formatMessage({ id: "entity_related" })}>
                                <StyledText as="p" variant="B3" style={{ marginLeft: '5px' }}>
                                    {intl.formatMessage({ id: "entity_related" })}
                                </StyledText>
                                {(selectedRadio === intl.formatMessage({ id: "entity_related" })) && (
                                    <Checkbox.Group onChange={handleCheckboxChange}>
                                        <Checkbox value={intl.formatMessage({ id: "your_entity_does_not_meet_our_criteria" })}>{intl.formatMessage({ id: "your_entity_does_not_meet_our_criteria" })}</Checkbox>
                                    </Checkbox.Group>
                                )}
                            </Radio>
                            <Radio className="radioCompany" value={intl.formatMessage({ id: "programme_related" })} checked={selectedRadio === intl.formatMessage({ id: "programme_related" })}>
                                <StyledText as="p" variant="B3" style={{ marginLeft: '5px' }}>
                                    {intl.formatMessage({ id: "programme_related" })}
                                </StyledText>
                                {(selectedRadio === intl.formatMessage({ id: "programme_related" })) && (
                                    <Checkbox.Group onChange={handleCheckboxChange}>
                                        <Checkbox value={intl.formatMessage({ id: "your_project_does_not_align_closely_enough_with_our_objectives" })}>{intl.formatMessage({ id: "your_project_does_not_align_closely_enough_with_our_objectives" })}</Checkbox>
                                        <Checkbox value={intl.formatMessage({ id: "other_projects_more_closely_aligned_with_our_objectives" })}>{intl.formatMessage({ id: "other_projects_more_closely_aligned_with_our_objectives" })}</Checkbox>
                                        <Checkbox value={intl.formatMessage({ id: "your_project_did_not_fully_meet_our_eligibility_criteria" })}>{intl.formatMessage({ id: "your_project_did_not_fully_meet_our_eligibility_criteria" })}</Checkbox>
                                        <Checkbox value={intl.formatMessage({ id: "funding_in_this_round_has_already_been_disbursed_to_similar_projects" })}>{intl.formatMessage({ id: "funding_in_this_round_has_already_been_disbursed_to_similar_projects" })}</Checkbox>
                                    </Checkbox.Group>
                                )}
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <StyledText as="p" variant="B3d" className="title">
                        {intl.formatMessage({ id: 'add_a_message' })}
                    </StyledText>
                    <Form.Item
                        name="declineTextField"
                        className='customFormItem'
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({ id: "please_enter" }),
                            },
                        ]}
                    >
                        <TextArea
                            className="locationNameInput"
                            placeholder={intl.formatMessage({ id: "add_a_message" })}
                        />
                    </Form.Item>
                    <div className='dFlexModal'>
                        <Button
                            variant="secondary"
                            htmlType="button"
                            onClick={() => setDeclineApplicationModal(false)}>
                            {intl.formatMessage({ id: "cancel" })}
                        </Button>
                        <Button
                            variant="danger"
                            htmlType="button"
                            onClick={() => handleDecline()}>
                            {intl.formatMessage({ id: "decline" })}
                        </Button>
                    </div>
                </Form>
            </CustomModal>
            <CustomModal
                title={intl.formatMessage({ id: 'confirm_pre_approval' })}
                open={preApproveApplicationModal}
                onOk={() => setPreApproveApplicationModal(false)}
                onCancel={() => setPreApproveApplicationModal(false)}
                closable
                footer={null}
            >
                <>
                    <StyledText as="p" variant="B3" className="title">
                        {intl.formatMessage({ id: 'please_confirm_your_pre_approval' })}
                    </StyledText>
                    <StyledText as="p" variant="B3" className="title">
                        {intl.formatMessage({ id: 'pre_approving_will_not_notify_the_applicant' })}
                    </StyledText>
                    <div className='dFlexModal'>
                        <Button
                            variant="secondary"
                            htmlType="button"
                            onClick={() => setDeclineApplicationModal(false)}>
                            {intl.formatMessage({ id: "cancel" })}
                        </Button>
                        <Button
                            variant="primary"
                            htmlType="button"
                            onClick={() => {
                                dispatch(addInteraction({
                                    programID,
                                    opportunityID: opportunityId,
                                    action: "preApproveOpportunity"
                                }))
                                setPreApproveApplicationModal(false)
                            }
                            }>
                            {intl.formatMessage({ id: "pre_approve" })}
                        </Button>
                    </div>
                </>
            </CustomModal>
            <CustomModal
                title={intl.formatMessage({ id: 'confirm_approval' })}
                open={approveConfirmationModal}
                onOk={() => setApproveConfirmationModal(false)}
                onCancel={() => setApproveConfirmationModal(false)}
                closable
                footer={null}
            >
                <Form
                    labelAlign="left"
                    form={form}
                    className="formContainer"
                    scrollToFirstError
                >
                    <StyledText as="p" variant="B3d" className="title">
                        {`${intl.formatMessage({ id: 'grant_award' })} : ${cashTargetCurrencyCode?.trim()}${totalCashRequested?.trim()}`}
                    </StyledText>
                    <StyledText as="p" variant="B3" className="title">
                        {intl.formatMessage({ id: 'please_confirm_you_wish_to_approve_this_application' })}
                    </StyledText>
                    <StyledText as="p" variant="B3" className="title">
                        {intl.formatMessage({ id: 'share_a_message' })}
                    </StyledText>
                    <Form.Item
                        name="confirmTextField"
                        className='customFormItem'
                    >
                        <TextArea
                            className="locationNameInput"
                            placeholder={intl.formatMessage({ id: "share_a_message" })}
                        />
                    </Form.Item>
                    <div className='dFlexModal'>
                        <Button
                            variant="secondary"
                            htmlType="button"
                            onClick={() => setApproveConfirmationModal(false)}>
                            {intl.formatMessage({ id: "cancel" })}
                        </Button>
                        <Button
                            variant="primary"
                            htmlType="button"
                            onClick={() => {
                                const allValues = form.getFieldsValue();
                                const data1 = {
                                    programID,
                                    opportunityID: opportunityId,
                                    message: allValues?.confirmTextField,
                                    messageStatus: "Application Approved"
                                }
                                dispatch(addInteraction({
                                    programID,
                                    opportunityID: opportunityId,
                                    action: "approveOpportunity"
                                }))
                                dispatch(sendDeclineConversation(data1))
                                setApproveConfirmationModal(false)
                            }}>
                            {intl.formatMessage({ id: "approve" })}
                        </Button>
                    </div>
                </Form>
            </CustomModal>

        </div>
    )
}

const StyledDashboardPage = styled(ProgramReviewHomePage)`
    &.dashboardPage {
        background: ${(props) => props.theme.backgroundCanvas};
        min-height: 100vh;
        display: flex;
        padding: 20px;
        gap: 20px;
        .opportunityOutsidePolygonReason{
        margin-left: 25px;
    }
        .flexColumn{
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .ant-tabs-tab-active .title {
            color: ${(props) => props.theme.bizGiveThemeColor} !important;
        }

        .ant-tabs-ink-bar {
            background: ${(props) => props.theme.bizGiveThemeColor} !important;
        }

        .goBackButton {
            width: max-content;
        }

        .companyTitleContainer {
            display: flex;
            padding: 10px 70px 10px 200px;
            justify-content: space-between;
            position: relative;
            align-items: center;

            .imageContainer {
                height: 144px;
                width: 144px;
                border-radius: 50%;
                top: -80px;
                left: 25px;
                position: absolute;

                img {
                    height: 144px;
                    width: 144px;
                    border-radius: 50%;
                    border: 1px solid black;
                }
            }
        }

        .bannerImageContainer {
            width: 100%;
            height: 216px;
            overflow: hidden;
            position: relative;
            background: white;
            border-radius: 10px;
        }

        .leftContainer {
            display: flex;
            flex-direction: column;
            gap: 10px;
            overflow: scroll;
            height: 90vh;
            width: 95%;

            svg {
                fill: #04ac9c !important;
            }

            .customAlert {
                svg {
                    fill: red !important;
                }
            }
        }

        .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
        }

        ul {
            margin: 0;
        }

        .ant-collapse-expand-icon {
            color: #313131 !important;
        }

        .mapIFrameStyle {
            border: 0;
        }

        .locationIcon {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        .locationContainer {
            margin-top: 50px;

            .locationDistance {
                font-style: italic;
            }

            svg {
                color: #f96675;
            }
        }

        .subContainer {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .descriptionContainer {
                p, li {
                    font-size: 14px;
                    font-weight: 500;
                    color: #242424;
                    font-family: sans-serif;
                }
            }
        }

        .ant-timeline-item-head {
            background: transparent !important;
        }

        .anticon-check {
            color: #58b55e;
        }

        .rightSideContainer {
            overflow: scroll;
            height: 90vh;
            width: 25vw;

            .rightContainer {
                display: flex;
                flex-direction: column;
                gap: 20px;
                background: white;
                padding: 12px 16px;
            }
        }

        .ant-collapse {
            border: 0;
            border-bottom: 1px solid #D7DDE1;
            border-radius: 0px;

            .ant-collapse-header {
                background: white !important;
            }
        }

        .ant-collapse-content-active {
            border-top: 0;
        }
    }
`;


export default StyledDashboardPage;
