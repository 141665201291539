import React, { useEffect, useState } from "react";
import { Table, Form, Divider, Input, Spin } from "antd";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { formatDate } from "../../../utils/utilities";
import { voteAnalytics, addVote, getVote, saveVote } from '../../../redux/actions';
import StyledText from "../../../components/StyledText";
import Select from "../../../components/CustomSelect";
import Button from "../../../components/Button";
import {
    voteAnalyticsSelector,
    addVoteSelector,
    getVoteSelector,
    programDataSelector,
    saveVoteSelector
} from "../../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../../redux/store";

const { TextArea } = Input;

const ProgramReview = ({ className }) => {
    const baseClassName = clsx("programReview", className);
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const [isEdit, setIsEdit] = useState(false);
    const voteAnalyticsData = useAppSelector(voteAnalyticsSelector);
    const addVoteData = useAppSelector(addVoteSelector);
    const getVoteData = useAppSelector(getVoteSelector);
    const programData = useAppSelector(programDataSelector);
    const { programID, opportunityID: opportunityId } = useParams();
    const { sumVotes, voterCount, sumCategories = {} } = voteAnalyticsData || {};
    const { voteID } = useAppSelector(saveVoteSelector) || {};

    const { votes } = addVoteData || {};
    const { createTime, message, votingAnswer, sumVoterVote } = getVoteData?.getvote?.[0] || {};
    const { votingScoreCriteria, votingCriteriaOptions } = programData || {};

    useEffect(() => {
        if (votingAnswer?.length) {
            const savedData = votingAnswer?.reduce((acc, el) => {
                const key = Object.keys(el)?.[0];
                if (key) {
                    acc[key] = `${el[key]?.value}-${el[key]?.description}`;
                }
                return acc;
            }, {});
            const data = {
                message: message,
                ...savedData
            }
            form.setFieldsValue(data);
        }
    }, [votingAnswer])

    useEffect(() => {
        if (programID) {
            dispatch(voteAnalytics({ programID, opportunityID: opportunityId }))
            dispatch(addVote({
                programID,
                opportunityID: opportunityId,
                action: "votes"
            }))
            dispatch(getVote({
                programID,
                opportunityID: opportunityId,
                action: "getvote"
            }))
        }
    }, [programID, opportunityId, voteID]);

    const getTableData = (list) => {
        if (list?.length > 0) {
            return list?.map((item, idx) => {
                const {
                    sumVoterVote,
                    voterEmail,
                    createDate,
                    message,
                    votingAnswer,
                    ID: interactionID,
                    voterName
                } = item || {};
                return {
                    key: idx,
                    sumVoterVote,
                    voterEmail,
                    createDate,
                    message,
                    votingAnswer,
                    interactionID,
                    voterName
                };
            });
        }
        return null;
    }

    const columns = [
        {
            title: <StyledText as="p" variant="H3">
                {intl.formatMessage({ id: "name" })}
            </StyledText>,
            dataIndex: 'voterName',
            key: 'voterName',
            render: (_, { voterName }) => (
                <StyledText as="p" variant="B3">
                    {voterName}
                </StyledText>
            ),
            fixed: 'left',
        },
        {
            title: <StyledText as="p" variant="H3">
                {intl.formatMessage({ id: "email" })}
            </StyledText>,
            dataIndex: 'voterEmail',
            render: (_, { voterEmail }) => (
                <StyledText as="p" variant="B3">
                    {voterEmail}
                </StyledText>
            ),
            key: 'voterEmail',
        },
        {
            title: <StyledText as="p" variant="H3">
                {intl.formatMessage({ id: "total_score" })}
            </StyledText>,
            dataIndex: 'sumVoterVote',
            key: 'sumVoterVote',
            render: (_, { sumVoterVote }) => (
                <StyledText as="p" variant="B3">
                    {sumVoterVote}
                </StyledText>
            ),
        },
        {
            title: <StyledText as="p" variant="H3">
                {intl.formatMessage({ id: "date" })}
            </StyledText>,
            key: 'createDate',
            dataIndex: 'createDate',
            fixed: 'right',
            render: (_, { createDate }) => (
                <StyledText as="p" variant="B3">
                    {formatDate(createDate)}
                </StyledText>
            ),
        },
        {
            title: <StyledText as="p" variant="H3">
                {intl.formatMessage({ id: "comment" })}
            </StyledText>,
            dataIndex: 'message',
            key: 'message',
            render: (_, { message }) => (
                <StyledText as="p" variant="B3">
                    {message}
                </StyledText>
            ),
        },
    ];

    const getNestedData = (list) => {
        if (list?.length > 0) {
            return list?.map((item, idx) => {
                const {
                    description,
                    value,
                } = item?.[Object.keys(item)?.[0]] || {};
                return {
                    key: idx,
                    description,
                    value,
                    categoryName: item && Object.keys(item)?.[0]
                };
            });
        }
        return null;
    }

    const expandedRowOpportunityRender = ({ interactionID }) => {
        const selectedProgramOpportunities = votes
            ?.find((item) => item.ID === interactionID);
        const opportunityColumns = [
            {
                title: intl.formatMessage({ id: 'category' }),
                dataIndex: 'categoryName',
                key: 'categoryName',
                fixed: 'left',
                render: (_, { categoryName }) => (
                    <StyledText as="p" variant="B3">
                        {categoryName}
                    </StyledText>
                ),
            },
            {
                title: intl.formatMessage({ id: 'category_score' }), dataIndex: 'value', key: 'value',
                render: (_, { value }) => (
                    <StyledText as="p" variant="B3">
                        {value}
                    </StyledText>
                ),
            },
            {
                title: intl.formatMessage({ id: 'description' }), dataIndex: 'description', key: 'description',
                render: (_, { description }) => (
                    <StyledText as="p" variant="B3">
                        {description}
                    </StyledText>
                ),
            },
        ];

        return (
            <Table
                columns={opportunityColumns}
                dataSource={getNestedData(
                    selectedProgramOpportunities?.votingAnswer
                )}
                pagination={false}
                className="donationsTable"
            />
        );
    };

    const handleProceed = (e) => {

    }

    function breakdownIntoObject(input) {
        const { message, ...categories } = input || {};
        const votingAnswer = categories
            ? Object.entries(categories).map(([key, value]) => {
                const [valueNum, description] = (typeof value === 'string' && value.includes("-"))
                    ? value.split("-").map(item => item.trim())
                    : [];

                return {
                    [key]: {
                        description: description || "",
                        value: valueNum ? Number(valueNum) : NaN
                    }
                };
            })
            : [];

        return {
            votingAnswer,
        };
    }

    return (
        <div className={baseClassName}>
            {(!sumVotes) ? (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={!sumVotes} />
                </div>
            ) : <>
                <div className="topContainer">
                    <div className="topSubContainer">
                        <div className="subContainer">
                            <StyledText as="p" variant="H2">
                                {intl.formatMessage({ id: "total" })}
                            </StyledText>
                            <StyledText as="p" variant="B3d">
                                {`${intl.formatMessage({ id: "total_score" })} : ${sumVotes}`}
                            </StyledText>
                            <StyledText as="p" variant="B3d">
                                {`${intl.formatMessage({ id: "voters" })} : ${voterCount}`}
                            </StyledText>
                            <StyledText as="p" variant="B3d">
                                {`${intl.formatMessage({ id: "category_totals" })}`}
                            </StyledText>
                            {sumCategories && Object.entries(sumCategories).map(([key, value]) => (
                                <StyledText key={key} as="p" variant="B3">
                                    {key} : {value}
                                </StyledText>
                            ))}

                        </div>
                    </div>
                </div>
                <div className='subContentContainer'>
                    <Table
                        className="components-table-opportunity"
                        columns={columns}
                        dataSource={getTableData(votes)}
                        expandable={{ expandedRowRender: (record) => expandedRowOpportunityRender({ interactionID: record?.interactionID }) }}
                        pagination={false}
                    />
                </div>
                <div className="topContainer">
                    <div className="topSubContainer">
                        <Form
                            labelAlign="left"
                            form={form}
                            onFinish={handleProceed}
                            className="formContainer"
                            scrollToFirstError
                        >
                            <div className="subContainer">
                                <StyledText as="p" variant="H2">
                                    {intl.formatMessage({ id: "my_vote" })}
                                </StyledText>
                                <StyledText as="p" variant="B3a">
                                    {`${intl.formatMessage({ id: "saved" })} : ${createTime}`}
                                </StyledText>
                                <StyledText as="p" variant="B3d">
                                    {`${intl.formatMessage({ id: "vote" })}`}
                                </StyledText>
                                {isEdit && votingScoreCriteria?.map((el) => <div key={el} className="dflex">
                                    <StyledText as="p" variant="B3" className="labelName">
                                        {el}
                                    </StyledText>
                                    <Form.Item label="" name={el} rules={[
                                        {
                                            required: false,
                                            message: 'Please select',
                                        },
                                    ]}>
                                        <Select
                                            allowClear
                                            placeholder={intl.formatMessage({ id: "select_score" })}
                                            type="formSelect"
                                            options={votingCriteriaOptions?.map((value) => ({
                                                key: `${value.value}-${value.description}`,
                                                label: `${value.value}-${value.description}`,
                                                value: `${value.value}-${value.description}`,
                                            }))}
                                        />
                                    </Form.Item>
                                </div>)}
                                {!isEdit && votingAnswer?.map((el) => <div key={el} className="dflex">
                                    <StyledText as="p" variant="B3" className="labelName">
                                        {Object.keys(el)?.[0]}
                                    </StyledText>
                                    <StyledText as="p" variant="B3d">
                                        {`${el?.[Object.keys(el)?.[0]]?.value} - ${el?.[Object.keys(el)?.[0]]?.description}`}
                                    </StyledText>
                                </div>)
                                }
                                {!isEdit && <div className="dflex">
                                    <StyledText as="p" variant="B3" className="labelName">
                                        {intl.formatMessage({ id: "total" })}
                                    </StyledText>
                                    <StyledText as="p" variant="B3d">
                                        {sumVoterVote}
                                    </StyledText>
                                </div>}
                                <StyledText as="p" variant="B3d">
                                    {intl.formatMessage({ id: "comments" })}
                                </StyledText>
                                {!isEdit && <StyledText as="p" variant="B3">
                                    {message}
                                </StyledText>}
                                {isEdit && <Form.Item label="" name="message" rules={[
                                    {
                                        required: false,
                                        message: 'Please select',
                                    },
                                ]}>
                                    <TextArea
                                        className="textContent"
                                        maxLength="300"
                                        placeholder={intl.formatMessage({ id: "what_are_your_overall_comments" })}
                                        rows={2}
                                    />
                                </Form.Item>}
                            </div>
                            <div className='buttonContainer'>
                                <Divider />
                                <div className='buttonContentContainer'>
                                    {isEdit && <div className='nextAndPreviousContainer'>
                                        <Button
                                            variant="secondary"
                                            type="button"
                                            onClick={() => { setIsEdit(false) }}
                                        >
                                            {intl.formatMessage({ id: "cancel" })}
                                        </Button>
                                        <Button
                                            variant="primary"
                                            htmlType="button"
                                            onClick={() => {
                                                const allValues = form.getFieldsValue();
                                                const data = {
                                                    action: "add",
                                                    programID,
                                                    opportunityID: opportunityId,
                                                    ...breakdownIntoObject(allValues),
                                                    message: allValues?.message,
                                                }
                                                dispatch(saveVote(data))
                                                setIsEdit(false)
                                            }}
                                        >
                                            {intl.formatMessage({ id: "save" })}
                                        </Button>
                                    </div>}
                                    {!isEdit && <div className="saveAndExitButton">
                                        <Button
                                            variant="primary"
                                            htmlType="button"
                                            onClick={() => setIsEdit(true)}
                                        >
                                            {intl.formatMessage({ id: "edit" })}
                                        </Button>
                                    </div>}
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </>}
        </div>
    );
};

const StyledProgramReview = styled(ProgramReview)`
  &.programReview {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .dflex{
        display: flex;
        align-items: center;
        gap: 10px;
        .ant-form-item{
            margin-bottom: 0;
        }
        .labelName{
            width: 100px;
        }
    }
    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        bottom: 0px;
        background: white;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
    .topContainer{
        padding: 20px;
        display: flex;
        width: 20vw;
        justify-content: space-between;
        background: white;
          box-shadow: 0px 3px 16px #0000000d;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        .topSubContainer{
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
      }
    .subContentContainer{
        .ant-table-wrapper{
        width: 50vw !important;
    }
    }
  }
`;

export default StyledProgramReview;
