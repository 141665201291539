import React, { useEffect } from 'react';
import clsx from "clsx";
import { jwtDecode } from 'jwt-decode';
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import styled from "styled-components";
import { getProfileData, editUserProfile } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import {
    toggleSpinnerSelector,
    tokenSelector,
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";

const CharityProfilePreview = ({ className }) => {
    const baseClassName = clsx("charityProfilePreview", className);
    const intl = useIntl();
    const navigate = useNavigate();
    const token = useAppSelector(tokenSelector) || localStorage.getItem("id_Token");
    const dispatch = useAppDispatch();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const decodedToken = jwtDecode(token);
    const referrer = JSON.parse(decodedToken["custom:referrer"]) || {}

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        dispatch(getProfileData());
        dispatch(editUserProfile({
            "action": "update",
            "referrer": { ...referrer?.referrer, completed: true },
        }));
    }, []);

    const handleDiscover = () => {
        navigate(`/impact-maker/programme/${referrer?.programId}`)
    }

    const handleApply = () => {
        navigate(`/impact-maker/programme/${referrer?.programId}`)
    }

    return (
        <div className={baseClassName}>
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <div className='mainContentContainer'>
                <div className='contentContainer'>
                    <StyledText as="p" variant="H1">
                        {intl.formatMessage({ id: "your_profile_is_complete" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "you_can" })}
                        <StyledText as="span" variant="B3d">
                            {` ${intl.formatMessage({ id: "edit_title_caption" })} `}
                        </StyledText>
                        <StyledText as="span" variant="B3">
                            {intl.formatMessage({ id: "your_profile_at_any_point_by_clicking_into" })}
                        </StyledText>
                    </StyledText>
                    <StyledText as="p" variant="H2">
                        {intl.formatMessage({ id: "what_happens_next" })}
                    </StyledText>
                    <div className='whiteContainer'>
                        <div>
                            <StyledText as="p" variant="B3d">
                                {intl.formatMessage({ id: "start_your_application" })}
                            </StyledText>
                            <StyledText as="p" variant="B3">
                                {intl.formatMessage({ id: "if_you_accessed_bizGive_via_a_company_programme" })}
                            </StyledText>
                        </div>
                        <div>
                            <Button
                                variant="primary"
                                onClick={() => handleApply()}
                                htmlType="button">
                                {intl.formatMessage({ id: "apply" })}
                            </Button>
                        </div>
                    </div>
                    <div className='whiteContainer'>
                        <div>
                            <StyledText as="p" variant="B3d">
                                {intl.formatMessage({ id: "discover_company_programmes" })}
                            </StyledText>
                            <StyledText as="p" variant="B3">
                                {intl.formatMessage({ id: "click_discover_to_view_and_apply_to_live_company_support_programmes" })}
                            </StyledText>
                        </div>
                        <div>
                            <Button
                                variant="primary"
                                onClick={() => handleDiscover()}
                                htmlType="button">
                                {intl.formatMessage({ id: "discover" })}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const StyledCharityProfilePreview = styled(CharityProfilePreview)`
&.charityProfilePreview{
    background: ${(props) => props.theme.backgroundCanvas};
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    height: 180vh;

    .mainContentContainer{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .contentContainer{
        display: flex;
        flex-direction: column;
        gap: 12px;
        .formContainer{
        display: flex;
        gap: 12px;
        flex-direction: column;
    }
    }

    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }
    .whiteContainer{
        background: white;
        padding: 10px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        width: 50vw;
        align-items: center;
    }
}`;

export default StyledCharityProfilePreview;
