import React, { useEffect } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { Table, Space } from "antd";
import { Helmet as HelmetAsync } from "react-helmet-async";
import styled from "styled-components";
import { useIntl } from "react-intl";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";
import {
    profileSelector,
    discoverDataSelector
} from "../../redux/selectors";
import StyledText from "../../components/StyledText";
import LinkComponent from "../../components/LinkComponent";
import {
    getDiscoverData,
    getProfileData
} from "../../redux/actions";
import { formatDate } from "../../utils/utilities";

const CharityDiscoverFunding = ({ className }) => {
    const intl = useIntl();
    const baseClassName = clsx("charityDiscoverFunding", className);
    const dispatch = useAppDispatch();
    const { charity: { operatingLocation } = {} } = useAppSelector(profileSelector) || {};
    const discoverData = useAppSelector(discoverDataSelector) || [];
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getProfileData());
    }, [])

    useEffect(() => {
        if (operatingLocation?.length)
            dispatch(getDiscoverData({
                sortBy: "programName",
                asc: "true",
                filterBy: { operatingLocation: ["United Kingdom"] },
                limit: "100"
            }))
    }, [operatingLocation]);

    const getTableData = (list) => {
        if (list?.length > 0) {
            return list?.map((item, idx) => {
                const {
                    programName,
                    opportunityName,
                    companyName,
                    ID,
                    acceptStartDate,
                    acceptEndDate,
                    applicationAcceptPeriod,
                    decisionStartDate,
                    decisionEndDate,
                    decisionPeriod,
                    programmeLocation
                } = item || {};
                return {
                    key: idx,
                    programName,
                    opportunityName,
                    companyName,
                    ID,
                    acceptStartDate,
                    acceptEndDate,
                    applicationAcceptPeriod,
                    decisionStartDate,
                    decisionEndDate,
                    decisionPeriod,
                    programmeLocation
                };
            });
        }
        return null;
    }

    const columns = [
        {
            title: <StyledText as="p" variant="H3">
                {intl.formatMessage({ id: "programme" })}
            </StyledText>,
            dataIndex: 'programName',
            key: 'programName',
            render: (_, { programName }) => (
                <div className="nameContainer">
                    <div className="blockWithText">
                        {programName}
                    </div>
                </div>
            ),
            fixed: 'left',
        },
        {
            title: <StyledText as="p" variant="H3">
                {intl.formatMessage({ id: "organisation" })}
            </StyledText>,
            dataIndex: 'companyName',
            key: 'companyName',
            render: (_, { companyName }) => {
                return (<StyledText as="p" variant="B3">
                    {companyName}
                </StyledText>)
            }
        },
        {
            title: <StyledText as="p" variant="H3">
                {intl.formatMessage({ id: "applications_accepted" })}
            </StyledText>,
            dataIndex: 'acceptStartDate',
            key: 'acceptStartDate',
            render: (_, { acceptStartDate, acceptEndDate, applicationAcceptPeriod }) => {
                return (<StyledText as="p" variant="B3">
                    {acceptStartDate?.trim() && acceptEndDate?.trim() ? <StyledText as="p" variant="B3">
                        {`${formatDate(acceptStartDate)} - ${formatDate(acceptEndDate)}`}
                    </StyledText> : <StyledText as="p" variant="B3">
                        {applicationAcceptPeriod}
                    </StyledText>}
                </StyledText>)
            }
        },
        {
            title: <StyledText as="p" variant="H3">
                {intl.formatMessage({ id: "decision_made" })}
            </StyledText>,
            dataIndex: 'decision_made',
            key: 'decision_made',
            render: (_, { decisionStartDate, decisionEndDate, decisionPeriod }) => {
                return (<StyledText as="p" variant="B3">
                    {decisionStartDate?.trim() && decisionEndDate?.trim() ? <StyledText as="p" variant="B3">
                        {`${formatDate(decisionStartDate)} - ${formatDate(decisionEndDate)}`}
                    </StyledText> : <StyledText as="p" variant="B3">
                        {decisionPeriod}
                    </StyledText>}
                </StyledText>)
            },
        },
        {
            title: <StyledText as="p" variant="H3">
                {intl.formatMessage({ id: "location" })}
            </StyledText>,
            key: 'location',
            dataIndex: 'location',
            render: (_, { programmeLocation }) => {
                return (<StyledText as="p" variant="B3">
                    {programmeLocation?.features?.[0]?.preferred_name || programmeLocation?.features?.[0]?.geometry?.preferred_name}
                </StyledText>)
            }
        },
        {
            title: <StyledText as="p" variant="H3">
                {intl.formatMessage({ id: "action" })}
            </StyledText>,
            key: 'actions',
            dataIndex: 'action',
            render: (_, { ID }) => (
                <Space size="middle">
                    <button className='button' onClick={() => navigate(`/impact-maker/programme/${ID}`)
                    }>{intl.formatMessage({ id: "review" })}</button>
                </Space>
            ),
            fixed: 'right',
        },
    ];

    const isValid = Array.isArray(discoverData) && discoverData?.length === 0

    return (
        <div className={baseClassName}>
            <HelmetAsync>
                <title>Charity Discover Page</title>
            </HelmetAsync>
            <div className='subContentContainer'>
                <StyledText as="p" variant="H1" className='mainTitle'>
                    {intl.formatMessage({ id: "discover_funding_opportunities" })}
                </StyledText>
                <div className="mainContent">
                    <StyledText as="p" variant="B3" className='subTitle'>
                        {intl.formatMessage({ id: "available_programmes_filtered_by_your_entitys_area_of_operation" })}
                        <StyledText as="span" variant="B3d">
                            {operatingLocation?.join(", ")}.
                        </StyledText>
                        <LinkComponent onClick={() => navigate("/impact-maker/profile")}>
                            <StyledText as="span" variant="B3b">
                                {intl.formatMessage({ id: "change_here" })}
                            </StyledText>
                        </LinkComponent>
                    </StyledText>
                    <Table columns={columns} loading={isValid} dataSource={getTableData(discoverData)} />
                </div>
            </div>
        </div>
    );
};

const StyledCharityDiscoverFunding = styled(CharityDiscoverFunding)`
  &.charityDiscoverFunding {
    background: ${(props) => props.theme.backgroundCanvas};
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 60px 10px;
    .mainTitle{
        margin-bottom: 20px;
    }
    .mainContent{
        background: white;
        padding: 20px;
        border-radius: 10px;
    }
    .subTitle{
        display: flex;
        white-space: nowrap;
        align-items: center;
        gap: 5px;
        margin-bottom: 20px;
    }
    .nameContainer{
      display: flex;
      gap: 10px;
    }
    .ant-tabs-tab-active {
      .title {
        color: ${(props) => props.theme.bizGiveThemeColor} !important;
      }
    }
    .ant-tabs-ink-bar {
      background: ${(props) => props.theme.bizGiveThemeColor} !important;
    }
    .button{
        border: 0;
        background: transparent;
        color: #5550F7;
        cursor: pointer;
        min-width: 100px;
    }
  }
`;

export default StyledCharityDiscoverFunding;
