import React from 'react';
import { Avatar } from "antd";
import styled from "styled-components";
import clsx from "clsx";
import inkind from "../../../assets/inkind.svg";
import StyledText from "../../../components/StyledText";

const InKind = ({ title, inKindUse, className }) => {
    const baseClassName = clsx("inKind", className);
    return (
        <div className={baseClassName}>
            <div className="supportContainer inKindContainer">
                <div className="titleContainer">
                    <div>
                        <Avatar src={inkind} />
                    </div>
                    <div>
                        <StyledText as="p" variant="H3">
                            {title}
                        </StyledText>
                    </div>
                </div>
                <ul>
                    {inKindUse?.map((el) => (
                        <li key={el?.inKindActivityDescription}>
                            <span className="outputNumber">{el?.inKindOfferingDescription}</span> for {el?.inKindActivityDescription}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

const StyledInKindContainer = styled(InKind)`
&.inKind{
    .supportContainer {
    padding: 16px;
    width: max-content;
    width: 35vw;
        &.inKindContainer {
          background: transparent linear-gradient(223deg, #ffffff 0%, #ffaffa 100%) 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 16px #0000000d;
          border-radius: 10px;
        }
  }
  .outputNumber{
        background: #dee3ed;
        border-radius: 5px;
        padding: 1px 8px;
        border: 1px solid grey; 
    }
  .titleContainer {
          display: flex;
          gap: 2px;
          align-items: center;
        }
  .additionalFunding{
        background: white !important;
        padding: 20px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
  .expenditureValues{
        background: #5DB76A;
        border-radius: 5px;
        padding: 1px 8px;
        border: 1px solid #5DB76A;
    }
  .flexBox{
        display: flex;
        align-items: flex-start;
        gap: 20px;
        &.justifyContent{
            justify-content: flex-start;
        }
        .flexBoxChild{
            display: flex;
            flex-direction: column;
            gap: 10px;
            .flexColumn{
                display: flex;
                flex-direction: column;
                .noBreak{
                    white-space: nowrap;
                }
            }
        }
    }
}
`
export default StyledInKindContainer;
