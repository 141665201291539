import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Divider, Form, Input, Spin, Steps, Button as AntdButton, Space } from "antd";
import styled from "styled-components";
import {
    PlusOutlined
} from "@ant-design/icons";
import { getProfileData, toggleSpinner, editCharityProfile, setEditCharityProfile, setCreateCharityProfile } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import {
    toggleSpinnerSelector,
    profileSelector,
    editCharityProfileSelector
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const CharityTrustees = ({ className }) => {
    const baseClassName = clsx("charityTrustees", className);
    const intl = useIntl();
    const [isFieldOpened, setIsFieldOpened] = useState(false);
    const [savedClicked, setSavedClicked] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const { charity: { ID = '', legalName, trustees } = {} } = useAppSelector(profileSelector) || {};

    const { message } = useAppSelector(editCharityProfileSelector) || {};

    useEffect(() => {
        if ((message) && !savedClicked) {
            dispatch(setEditCharityProfile(''))
            dispatch(setCreateCharityProfile(''))
            dispatch(toggleSpinner(false));
            navigate("/impact-maker/profile/create/locations");
        }
    }, [message])

    useEffect(() => {
        if (legalName) {
            const data = {
                ...(trustees && { trustees }),
            };
            form.setFieldsValue(data);
        }
    }, [legalName]);

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        dispatch(getProfileData());
    }, []);

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const handleProceed = (e) => {
        const formData = form.getFieldsValue();

        const data = {
            ID,
            ...formData
        }
        dispatch(editCharityProfile(data))
    }

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
        }, 300);
    }

    const handlePrevious = () => {
        navigate("/impact-maker/profile/create/legal-structure")
    }

    return (
        <div className={baseClassName}>
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <Steps
                current={0}
                items={[
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_1" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3c" className='mainTitle'>
                            {intl.formatMessage({ id: "entity_details" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_2" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "locations" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_3" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "contact_details" })}
                        </StyledText>,
                    }
                ]}
            />
            <div className='contentContainer'>
                <div>
                    <StyledText as="p" variant="H1" className='mainTitle'>
                        {intl.formatMessage({ id: "trustees" })}
                    </StyledText>
                    <StyledText as="p" variant="B3d" className='mainTitle'>
                        {intl.formatMessage({ id: "please_provide_the_name_of_your_trustees_or_directors" })}
                    </StyledText>
                    <StyledText as="p" variant="B3" className='mainTitle'>
                        {intl.formatMessage({ id: "a_minimum_of_two_is_required_only_1_is_required_if_you_are_a_uk_cic" })}
                    </StyledText>
                    <Form
                        {...formItemLayout}
                        labelAlign="left"
                        onFinish={handleProceed}
                        onFinishFailed={onFinishFailed}
                        form={form}
                        className="formContainer"
                        scrollToFirstError
                    >
                        <div className="mainContentContainer">
                            <div className="contentContainer">
                                <Form.List name="trustees" rules={[
                                    {
                                        validator: async (_, trustees) => {
                                            if (trustees?.length === 0 && !isFieldOpened) {
                                                return Promise.reject(new Error('Please add at least one trustee.'));
                                            }
                                        },
                                    },
                                ]}>
                                    {(fields, { add }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <Space key={key} className="optionsContainer">
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'name']}
                                                        className="formItemInput"

                                                        rules={[{ required: true, message: intl.formatMessage({ id: "please_enter" }) }]}
                                                    >
                                                        <Input placeholder={intl.formatMessage({ id: "insert" })} />
                                                    </Form.Item>
                                                </Space>
                                            ))}
                                            <Form.Item>
                                                <AntdButton
                                                    htmlType="button"
                                                    className="addButton"
                                                    type="dashed"
                                                    onClick={() => { add(); setIsFieldOpened(true); }}
                                                    icon={<PlusOutlined fill="red" />}
                                                    style={{
                                                        width: '200px',
                                                    }}
                                                >
                                                    <StyledText as="p" variant="B3">
                                                        {intl.formatMessage({ id: "add" })}
                                                    </StyledText>
                                                </AntdButton>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </div>
                            <div className="buttonContainer">
                                <Divider />
                                <div className="buttonContentContainer">
                                    <div className="nextAndPreviousContainer">
                                        <Button variant="secondary" type="button" onClick={() => handlePrevious()}>
                                            {intl.formatMessage({ id: "previous" })}
                                        </Button>
                                        <Button variant="primary" htmlType="submit">
                                            {intl.formatMessage({ id: "next" })}
                                        </Button>
                                    </div>
                                    <div className="saveAndExitButton">
                                        <Button variant="secondary" htmlType="button" onClick={() => handleSaveAndExit()}>
                                            {intl.formatMessage({ id: "save_n_exit" })}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>

                </div>
            </div>
        </div>
    )
}

const StyledCharityTrustees = styled(CharityTrustees)`
&.charityTrustees{
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    .googlePlaceInputItem{
        width: 16vw;
    }
    .mainTitle{
        margin-bottom: 20px;
    }
    .errorText{
        color: #ff4d4f;
    }
    .contentContainer{
        display: flex;
        flex-direction: column;
        gap: 12px;
        height: 100vh;
        .formContainer{
        display: flex;
        gap: 12px;
        flex-direction: column;
    }
    }
    .addButton{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .checkboxGroup{
        display: flex;
        flex-direction: column;
    }
    .optionsContainer{
        display: flex;
        align-items: center;
        gap: 30px;
        .for{
            margin-bottom: 10px;
        }
    }
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }

    .radioGroup {
            display: flex;
            flex-direction: column;
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
    .formItemTextField{
        .ant-form-item-control-input{
            width: 34.5vw !important;
        }
    }
    .formItemTextFieldPart{
        .ant-form-item-control-input{
            width: 16vw !important;
        } 
    }
    .helperText {
    color: #5271FF !important;
    font-weight: bold !important;
    margin-bottom: 0px !important;
  }
  .ant-form-item{
    margin-bottom: 10px;
  }
  .tipsContainer{
    background: #D7FEFB;
    border: 1px solid #04ac9c;
    padding: 20px;
    border-radius: 10px;
    width: max-content;
    height: max-content;
    .titleContainer {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        gap: 10px;
    }
  }
  .formItemInput{
    width: 300px;
  }
}`;

export default StyledCharityTrustees;
