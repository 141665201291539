import React from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useParams, useNavigate } from "react-router-dom";
import { Divider, Form, Spin } from "antd";
import styled from "styled-components";
import {
    toggleSpinner,
} from '../../redux/actions';
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import {
    toggleSpinnerSelector,
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";
import { getLocalStorageItem, formatDate } from "../../utils/utilities";

const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 20 },
};

const CharityFinalCreateStart = ({ className }) => {
    const baseClassName = clsx("charityFinalCreateStart", className);
    const intl = useIntl();
    const [form] = Form.useForm();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const contentData = JSON.parse(getLocalStorageItem('contentData') || '{}');
    const { ID } = useParams();

    const handleProceed = () => {
        dispatch(toggleSpinner(true));
        navigate(`/impact-maker/content/final/inputs/${ID}`);
        dispatch(toggleSpinner(false));
    }

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    return (
        <div className={baseClassName}>
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <Form
                {...formItemLayout}
                labelAlign="left"
                form={form}
                onFinish={handleProceed}
                onFinishFailed={onFinishFailed}
                className="formContainer"
                scrollToFirstError
            >
                <div className='summaryContainer'>
                    <StyledText as="p" variant="B3d">
                        {`${intl.formatMessage({ id: "this_interim_update_is_for" })}:`}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {contentData?.companyName} for the {contentData?.programName}
                    </StyledText>
                </div>
                <div className='summaryContainer'>
                    <StyledText as="p" variant="B3d">
                        {`${intl.formatMessage({ id: "final_report_deadline" })}:`}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {formatDate(contentData?.interactionDate)}
                    </StyledText>
                </div>
                <StyledText as="p" variant="H2">
                    {intl.formatMessage({ id: "form_guidance" })}
                </StyledText>
                <StyledText as="p" variant="B3">
                    {intl.formatMessage({ id: "in_the_form_you_will_be_asked_to_share_details_of_the_project_you_received_funding_for" })}
                </StyledText>
                <div className="contentContainer">
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "next_title" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "saves_your_data_and_moves_you_to_the_next_screen_your_answers_will_automatically_be_saved_unless_you_have_an_internet_connectivity_issue" })}
                    </StyledText>
                </div>
                <div className="contentContainer">
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "save_n_exit_title" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "saves_your_data_and_logs_you_out_of_bizGive" })}
                    </StyledText>
                </div>
                <div className="contentContainer">
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "submit_title" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "sends_your_form_to_the_company" })}
                    </StyledText>
                </div>
                <StyledText as="p" variant="B3">
                    {intl.formatMessage({ id: "to_access_a_saved_interim_update_click_into_impact" })}
                </StyledText>
                <StyledText as="p" variant="B3">
                    {intl.formatMessage({ id: "to_access_a_saved_interim_update_click_into_impact_content" })}
                </StyledText>
                <div className='buttonContainer'>
                    <Divider />
                    <Button
                        className='proceedButton'
                        variant="primary"
                        htmlType="submit"
                    >
                        {intl.formatMessage({ id: "start" })}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

const StyledCharityFinalCreateStart = styled(CharityFinalCreateStart)`
&.charityFinalCreateStart{
    background: white;
    display: flex;
    padding: 20px;
    flex-direction: column;
    height: 100vh;
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }
    .summaryContainer{
        display: flex;
        gap: 5px;
    }
    .mainTitle{
        margin-bottom: 20px;
    }
    .radioGroup{
        display: flex;
        flex-direction: column;
    }
    .formContainer{
        display: flex;
        gap: 20px;
        flex-direction: column;
        .contentContainer{
        display: flex;
        gap: 4px;
        }
        .standardMargin{
            margin-top: 10px;
        }
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -30px;
        .proceedButton{
            margin-bottom: 15px;
        }
    }
}`;

export default StyledCharityFinalCreateStart;