import React from "react";
import { Avatar, Divider } from "antd";
import styled from "styled-components";
import clsx from "clsx";
import { CheckOutlined } from "@ant-design/icons";
import grant from "../../../assets/grant.svg";
import StyledText from "../../../components/StyledText";

const GrantDetails = ({
    currencySymbol,
    totalCashRequested,
    wholeProjectFunding,
    grantBudgetPercentage,
    donationUse,
    additionalFundsSource,
    fundingSecured,
    oppFullBudgetNotRequired,
    intl,
    className
}) => {
    const baseClassName = clsx("grantsContainer", className);

    return (
        <div className={baseClassName}>
            <div className="supportContainer grantContainer">
                <div className="titleContainer">
                    <div>
                        <Avatar src={grant} />
                    </div>
                    <div>
                        <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "grants" })}
                        </StyledText>
                    </div>
                </div>
                <div className="flexBox">
                    <div className="flexBoxChild">
                        <div className="flexColumn">
                            <StyledText as="p" variant="B3" className="noBreak">
                                {intl.formatMessage({ id: "total_request" })}:
                            </StyledText>
                            <StyledText as="p" variant="B3c">
                                {currencySymbol || '$'}
                                {totalCashRequested?.trim()}
                            </StyledText>
                        </div>
                        <div className="flexColumn">
                            <StyledText as="p" variant="B3" className="noBreak">
                                {intl.formatMessage({ id: "total_project_budget" })}:
                            </StyledText>
                            <StyledText as="p" variant="B3c">
                                {currencySymbol || '$'}
                                {wholeProjectFunding?.trim()}
                            </StyledText>
                        </div>
                        <div className="flexColumn">
                            <StyledText as="p" variant="B3" className="noBreak">
                                {intl.formatMessage({ id: "funder_contribution" })}:
                            </StyledText>
                            <StyledText as="p" variant="B3c">
                                {`${Number(grantBudgetPercentage).toFixed(2)}%`}
                            </StyledText>
                        </div>
                    </div>
                    <Divider type="vertical" className="divider" />
                    <div className="flexBoxChild">
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "planned_expenditure" })}:
                        </StyledText>
                        {donationUse?.map((el) => (
                            <StyledText key={el?.itemDescription} as="p" variant="B3">
                                <span className="expenditureValues">
                                    {`${currencySymbol || '$'}${el.numberOfItems}`}
                                </span>
                                {` provides `}
                                <span className="expenditureValues">{`${el.monetaryUnit}`}</span>
                                {el?.itemDescription}
                            </StyledText>
                        ))}
                    </div>
                </div>
                {((additionalFundsSource && Object.keys(additionalFundsSource)?.length > 0) ||
                    Number(grantBudgetPercentage).toFixed(2) < 100) && (
                        <div className="additionalFunding">
                            <StyledText as="p" variant="B3d">
                                {intl.formatMessage({ id: "additional_funding" })}:
                            </StyledText>
                            <StyledText as="p" variant="B3">
                                <CheckOutlined />
                                {intl.formatMessage({
                                    id: "additional_funding_is_required_to_meet_the_total_opportunity_budget",
                                })}
                            </StyledText>
                            <div className="flexBox">
                                <Divider type="vertical" className="divider" />
                                <ul>
                                    <li>
                                        <StyledText as="p" variant="B3d">
                                            {intl.formatMessage({ id: "where_will_additional_funds_be_sourced" })}
                                        </StyledText>
                                        {additionalFundsSource?.internal && (
                                            <StyledText as="p" variant="B3">
                                                {intl.formatMessage({ id: "internal_resources" })}
                                            </StyledText>
                                        )}
                                        {additionalFundsSource?.external && (
                                            <StyledText as="p" variant="B3">
                                                {intl.formatMessage({ id: "external_funding" })}
                                            </StyledText>
                                        )}
                                    </li>
                                    <li>
                                        <StyledText as="p" variant="B3d">
                                            {intl.formatMessage({ id: "has_this_funding_been_secured" })}
                                        </StyledText>
                                        <StyledText as="p" variant="B3">
                                            {fundingSecured === "N"
                                                ? intl.formatMessage({ id: "no" })
                                                : intl.formatMessage({ id: "yes" })}
                                        </StyledText>
                                    </li>
                                    <li>
                                        <StyledText as="p" variant="B3d">
                                            {intl.formatMessage({ id: "can_this_project_go_ahead_without_additional_funding" })}
                                        </StyledText>
                                        <StyledText as="p" variant="B3">
                                            {oppFullBudgetNotRequired === "N"
                                                ? intl.formatMessage({ id: "no" })
                                                : intl.formatMessage({ id: "yes" })}
                                        </StyledText>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
};

const StyledGrantDetails = styled(GrantDetails)`
&.grantsContainer{
    .supportContainer {
    padding: 16px;
    width: max-content;
    width: 35vw;
    .divider{
        height: 100%;
    margin-inline: 10px;
    }
    &.grantContainer {
      background: transparent linear-gradient(223deg, #ffffff 0%, #b2f9bd 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 16px #0000000d;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
  .additionalFunding{
        background: white !important;
        padding: 20px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
  .expenditureValues{
        background: #5DB76A;
        border-radius: 5px;
        padding: 1px 8px;
        border: 1px solid #5DB76A;
    }
  .flexBox{
        display: flex;
        align-items: flex-start;
        gap: 20px;
        &.justifyContent{
            justify-content: flex-start;
        }
        .flexBoxChild{
            display: flex;
            flex-direction: column;
            gap: 10px;
            .flexColumn{
                display: flex;
                flex-direction: column;
                .noBreak{
                    white-space: nowrap;
                }
            }
        }
    }
}
`
export default StyledGrantDetails;

