import React, { useRef, useEffect } from 'react';
import clsx from "clsx";
import styled from "styled-components";

const GooglePlaceApiInput = ({ className, onSelectOfAddress, isDisabled, ...rest }) => {
    const autocompleteInput = useRef(null);
    const autocomplete = useRef(null);
    const baseClassName = clsx("googlePlaceInput ant-form-item-control-input", className);

    useEffect(() => {
        if (window?.google?.maps?.places?.Autocomplete) {
            autocomplete.current = new window.google.maps.places.Autocomplete(
                autocompleteInput.current,
                { types: ['geocode'] }
            );
            autocomplete.current.addListener('place_changed', handlePlaceChanged);
        } else {
            console.error('Google Maps JavaScript API is not loaded.');
        }
        return () => {
            if (autocomplete.current) {
                window.google.maps.event.clearInstanceListeners(autocomplete.current);
            }
        };
    }, []);

    const handlePlaceChanged = () => {
        const place = autocomplete.current.getPlace();
        if (!place.geometry) {
            console.error("Place contains no geometry");
            onSelectOfAddress(null);
            return;
        }
        onSelectOfAddress(place);
    };

    const handleBlur = () => {
        if (!autocompleteInput?.current?.value?.trim()) {
            onSelectOfAddress(null);
        }
    };

    return (
        <div className={baseClassName}>
            <input
                ref={autocompleteInput}
                id="autocomplete"
                type="text"
                disabled={isDisabled}
                onBlur={handleBlur}
                {...rest}
            />
        </div>
    );
};

const StyledGooglePlaceApiInput = styled(GooglePlaceApiInput)`
  &.googlePlaceInput {
    input {
        background: #ffffff;
        border-width: 1px;
        border-style: solid;
        border-color: #d9d9d9;
        box-sizing: border-box;
        margin: 0;
        padding: 4px 11px;
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;
        line-height: 1.5714285714285714;
        list-style: none;
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0;
        border-radius: 6px;
        transition: all 0.2s;

        @media only screen and (max-width: ${(props) =>
        props.theme.breakpoints?.small}) {
            width: 130px;
        }

        &:focus,
        &:focus-visible {
            border-color: #1677ff;
            box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
            outline: 0;
            background-color: #ffffff;
        }
        &::placeholder {
            color: #C0C0C0;
        }
    }
  }
`;

export default StyledGooglePlaceApiInput;

