import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Divider, Form, Input, Spin, Steps } from "antd";
import styled from "styled-components";
import { editOpportunity, getOpportunity, getProgramById, setEditOpportunitiesData, toggleSpinner, getUser } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import { getLocalStorageItem } from "../../utils/utilities";
import Select from "../../components/CustomSelect";
import {
    opportunityDataSelector,
    editOpportunityData,
    toggleSpinnerSelector,
    usersSelector
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 6 },
};

const CharityAdminDetails = ({ className }) => {
    const baseClassName = clsx("charityAdminDetails", className);
    const intl = useIntl();
    const [savedClicked, setSavedClicked] = useState(false);
    const [addedEmail, setAddedEmail] = useState([]);
    const opportunityData = useAppSelector(opportunityDataSelector) || {};
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const programReviewDetails = JSON.parse(getLocalStorageItem('programReviewDetails') || '{}');
    const { opportunityId, programID } = programReviewDetails || {};
    const { opportunity } = opportunityData || {};
    const editOpp = useAppSelector(editOpportunityData) || {};
    const { opportunityID } = editOpp || {};
    const users = useAppSelector(usersSelector);

    useEffect(() => {
        if (opportunityID && !savedClicked) {
            dispatch(setEditOpportunitiesData(''));
            dispatch(toggleSpinner(false));
            navigate('/impact-maker/applications/create/preview');
        }
    }, [opportunityID]);

    useEffect(() => {
        if (opportunity) {
            const initialValues = {
                BizGiveUser: opportunity.BizGiveUser || [],
            };
            setAddedEmail(opportunity.invitedUser)
            form.setFieldsValue(initialValues);
        }
    }, [opportunity]);

    useEffect(() => {
        if (opportunityId) {
            dispatch(getOpportunity(opportunityId));
        }
    }, [opportunityId, programID]);

    useEffect(() => {
        if (programID) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getProgramById(programID));
            dispatch(getUser());
        }
    }, [programID]);

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const handleProceed = () => {
        form.validateFields().then(values => {
            if (!addedEmail?.length) {
                form.setFields([
                    {
                        name: 'invitedUser',
                        errors: [intl.formatMessage({ id: 'please_press_add_to_save_the_invited_user' })],
                    },
                ]);
                return; 
            }
            const data = {
                BizGiveUser: values?.BizGiveUser || [],
                invitedUser: addedEmail,
                opportunityID: opportunityId,
                programID: programID
            };
            dispatch(toggleSpinner(true));
            dispatch(editOpportunity(data));
        }).catch(errorInfo => {
            console.log('Validate Failed:', errorInfo);
        });
    };

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
            navigate(`/impact-maker/programme/${programID}/${opportunityId}`);
        }, 300);
    };

    const handlePrevious = () => {
        navigate("/impact-maker/applications/create/award-criteria");
    };

    const handleAddEmail = () => {
        form.validateFields(['invitedUser', 'confirmEmail']).then(values => {

            if (values.invitedUser === values.confirmEmail) {
                setAddedEmail((prev) => {
                    if (values.invitedUser) {
                        return [...(Array.isArray(prev) ? prev : []), values.invitedUser];
                    }
                    return prev;
                });
                form.setFieldsValue({
                    invitedUser: undefined,
                    confirmEmail: undefined
                })
            } else {
                form.setFields([
                    {
                        name: 'confirmEmail',
                        errors: [intl.formatMessage({ id: 'the_new_email_that_you_entered_do_not_match' })],
                    },
                ]);
            }
        }).catch(info => {
            console.log('Validate Failed:', info);
        });
    };

    const { invitedUser, BizGiveUser} = form.getFieldsValue();

    return (
        <div className={baseClassName}>
            <Steps
                current={3}
                items={[
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_1" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "about_your_project" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_2" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "your_request" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_3" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "planned_impact_title" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_4" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3c" className='mainTitle'>
                            {intl.formatMessage({ id: "award_criteria" })}
                        </StyledText>,
                    },
                ]}
            />
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <div className='contentContainer'>
                <StyledText as="p" variant="H1" className='mainTitle'>
                    {intl.formatMessage({ id: "contact_details" })}
                </StyledText>
                <StyledText as="p" variant="B3" className='mainTitle'>
                    {intl.formatMessage({ id: "after_submitting_your_application_you_will_receive_email_notifications_from_BizGive_on_its_status" })}
                </StyledText>
                <StyledText as="p" variant="B3d" className='mainTitle'>
                    {intl.formatMessage({ id: "please_add_another_contact_in_case_of_your_absence" })}
                </StyledText>
                <StyledText as="p" variant="B3" className='mainTitle'>
                    {intl.formatMessage({ id: "if_theyre_already_on_BizGive_select_their_name_below" })}
                </StyledText>
                <Form
                    {...formItemLayout}
                    labelAlign="left"
                    form={form}
                    onFinish={handleProceed}
                    onFinishFailed={onFinishFailed}
                    className="formContainer"
                    scrollToFirstError
                >
                    <Form.Item
                        name="BizGiveUser"
                        rules={[
                            {
                                required: !invitedUser,
                                message: 'Please select',
                            },
                        ]}
                    >
                        <Select
                            allowClear
                            placeholder={intl.formatMessage({ id: "select_user" })}
                            type="formSelect"
                            mode="multiple"
                            options={users?.map((value) => ({
                                key: value?.userID,
                                label: `${value?.['custom:entityName']} - ${value?.email}`,
                                value: value?.userID,
                            }))}
                        />
                    </Form.Item>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "invite_a_colleague_to_join_by_entering_their_email_address_below" })}
                    </StyledText>
                    <Form.Item
                        name="invitedUser"
                        hasFeedback
                        rules={[
                            {
                                type: 'email',
                                message: intl.formatMessage({ id: 'the_input_is_not_valid_email' }),
                            },
                            {
                                required: !BizGiveUser?.length && !addedEmail?.length,
                                message: intl.formatMessage({ id: 'please_enter_email' }),
                            },
                        ]}
                    >
                        <Input placeholder={intl.formatMessage({ id: 'enter_email_address' })} />
                    </Form.Item>
                    <Form.Item name="confirmEmail" hasFeedback dependencies={['invitedUser']} rules={[
                        {
                            type: 'email',
                            message: intl.formatMessage({ id: 'the_input_is_not_valid_email' }),
                        },
                        {
                            required: !addedEmail?.length,
                            message: intl.formatMessage({ id: 'please_enter_email' }),
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('invitedUser') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(intl.formatMessage({ id: 'the_new_email_that_you_entered_do_not_match' })));
                            },
                        }),
                    ]}>
                        <Input placeholder={intl.formatMessage({ id: 'confirm_email_address' })} />
                    </Form.Item>
                    <Button
                        variant="primary"
                        type="button"
                        className="addItemButton"
                        onClick={handleAddEmail}
                    >
                        {intl.formatMessage({ id: "add" })}
                    </Button>
                    {addedEmail?.length > 0 && addedEmail.map((el) => <StyledText as="p" variant="B3" className='helperText'>
                        {el}
                    </StyledText>)}

                    <div className='buttonContainer'>
                        <Divider />
                        <div className='buttonContentContainer'>
                            <div className='nextAndPreviousContainer'>
                                <Button
                                    variant="secondary"
                                    type="button"
                                    onClick={() => handlePrevious()}>
                                    {intl.formatMessage({ id: "previous" })}
                                </Button>
                                <Button
                                    variant="primary"
                                    htmlType="submit"
                                >
                                    {intl.formatMessage({ id: "next" })}
                                </Button>
                            </div>
                            <div className="saveAndExitButton">
                                <Button
                                    variant="secondary"
                                    htmlType="button"
                                    onClick={() => handleSaveAndExit()}>
                                    {intl.formatMessage({ id: "save_n_exit" })}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    )
};

const StyledCharityAdminDetails = styled(CharityAdminDetails)`
&.charityAdminDetails{
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;

    .customSelect{
        width: 500px;
    }
    .contentContainer{
        display: flex;
        padding: 20px;
        height: 180vh;
        flex-direction: column;
    }
    .mainTitle{
        margin-bottom: 20px;
    }
    .formContainer{
        display: flex;
        gap: 12px;
        flex-direction: column;
    }

    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
    .addItemButton{
        width: max-content;
    }
    .helperText {
    color: #5271FF !important;
    font-weight: bold !important;
    margin-bottom: 0px !important;
  }
  .ant-form-item{
    margin-bottom: 10px;
  }
}`;

export default StyledCharityAdminDetails;
