import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Divider, Form, Input, Checkbox, Spin, Steps } from "antd";
import styled from "styled-components";
import GooglePlaceApiInput from "../../components/GoogleInput";
import { getProfileData, toggleSpinner, createCharityProfile, editCharityProfile, setEditCharityProfile, setCreateCharityProfile } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import {
    toggleSpinnerSelector,
    profileSelector,
    createCharityProfileSelector,
    editCharityProfileSelector
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";

const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 4 },
};

const CharityEntityName = ({ className }) => {
    const baseClassName = clsx("charityEntityName", className);
    const intl = useIntl();
    const [formData, setFormData] = useState({});
    const [registeredAddress, setRegisteredAddress] = useState();
    const [addressError, setAddressError] = useState("");
    const [savedClicked, setSavedClicked] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const { charity: { ID = '', legalName, tradingName, registeredAddress: savedRegisteredAddress, hasTradingName: savedHasTradingName } = {} } = useAppSelector(profileSelector) || {};

    const { message: createCharityMessage } = useAppSelector(createCharityProfileSelector) || {};
    const { message } = useAppSelector(editCharityProfileSelector) || {};

    useEffect(() => {
        if ((message || createCharityMessage) && !savedClicked) {
            dispatch(setEditCharityProfile(''))
            dispatch(setCreateCharityProfile(''))
            dispatch(toggleSpinner(false));
            navigate("/impact-maker/profile/create/legal-structure");
        }
    }, [message, createCharityMessage])


    useEffect(() => {
        if (legalName) {
            const data = {
                ...(legalName && { legalName }),
                ...(tradingName && { tradingName }),
                ...(savedRegisteredAddress && { registeredAddress: savedRegisteredAddress?.addressLine1 }),
                ...(savedHasTradingName && { hasTradingName: savedHasTradingName }),
            };

            form.setFieldsValue(data);
            setFormData(data);
            if (savedRegisteredAddress?.addressLine1) {
                setRegisteredAddress(savedRegisteredAddress?.addressLine1)
            }
        }
    }, [legalName]);

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        dispatch(getProfileData());
    }, []);

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const handleProceed = () => {
        const formData = form.getFieldsValue();
        const data = {
            legalName: formData.legalName,
            tradingName: (formData.hasTradingName === 'Y' || formData.hasTradingName === true) ? formData.tradingName : formData.legalName,
            hasTradingName: formData.hasTradingName ? "Y" : "N",
            registeredAddress: registeredAddress
        };

        if (!registeredAddress) {
            setAddressError(intl.formatMessage({ id: "enter" }));
            return;
        }

        if (!ID) {
            dispatch(createCharityProfile({ ...data, profilePercentage: "0" }));
        } else {
            dispatch(editCharityProfile(data));
        }
    };

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
        }, 300);
    }

    const handlePrevious = () => {
        navigate("/impact-maker/applications/create/dates")
    }

    const onFormChange = (_, allValues) => {
        setFormData(allValues);
    };

    const { hasTradingName } = formData || {};

    const onSelectOfAddress = (address) => {
        setRegisteredAddress({ addressLine1: address?.formatted_address })
        setAddressError("");
    }

    return (
        <div className={baseClassName}>
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <Steps
                current={1}
                items={[
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_1" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3c" className='mainTitle'>
                            {intl.formatMessage({ id: "entity_details" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_2" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "locations" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_3" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "contact_details" })}
                        </StyledText>,
                    }
                ]}
            />
            <div className='contentContainer'>
                <div>
                    <StyledText as="p" variant="H1" className='mainTitle'>
                        {intl.formatMessage({ id: "name_n_address" })}
                    </StyledText>
                    <StyledText as="p" variant="B3d" className='mainTitle'>
                        {intl.formatMessage({ id: "what_is_the_legal_Name_of_your_entity" })}
                    </StyledText>
                    <Form
                        {...formItemLayout}
                        labelAlign="left"
                        onFinish={handleProceed}
                        onFinishFailed={onFinishFailed}
                        form={form}
                        className="formContainer"
                        scrollToFirstError
                        onValuesChange={onFormChange}
                    >
                        <div className="mainContentContainer">
                            <div className="contentContainer">
                                <Form.Item
                                    name="legalName"
                                    className="formItem"
                                    rules={[
                                        {
                                            whitespace: true,
                                            required: true,
                                            message: intl.formatMessage({ id: "enter" }),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={intl.formatMessage({
                                            id: "enter",
                                        })}
                                    />
                                </Form.Item>
                                <StyledText as="span" variant="B3d">
                                    {intl.formatMessage({ id: "do_you_have_a_different_trading_name_or_display_name" })}
                                    <StyledText as="span" variant="B3a">
                                        {` (${intl.formatMessage({ id: "optional" })})`}
                                    </StyledText>
                                </StyledText>
                                <Form.Item
                                    name="hasTradingName"
                                    valuePropName="checked"
                                    rules={[{ required: false }]}
                                >
                                    <Checkbox value="Y">
                                        <StyledText as="span" variant="B3" >
                                            {intl.formatMessage({ id: "yes" })}
                                        </StyledText>
                                    </Checkbox>
                                </Form.Item>
                                {hasTradingName === "Y" && <Form.Item
                                    name="tradingName"
                                    className="formItem"
                                    rules={[
                                        {
                                            whitespace: true,
                                            required: true,
                                            message: intl.formatMessage({ id: "enter" }),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={intl.formatMessage({
                                            id: "enter",
                                        })}
                                    />
                                </Form.Item>}
                                <StyledText as="p" variant="B3d">
                                    {intl.formatMessage({ id: "what_is_your_registered_address" })}
                                </StyledText>
                                <GooglePlaceApiInput
                                    className="formItem googlePlaceInputItem"
                                    placeholder={intl.formatMessage({
                                        id: "enter",
                                    })}
                                    defaultValue={registeredAddress?.addressLine1 || registeredAddress}
                                    onSelectOfAddress={onSelectOfAddress}
                                />
                                {addressError && <div className="errorText">{addressError}</div>}
                            </div>
                            <div className='buttonContainer'>
                                <Divider />
                                <div className='buttonContentContainer'>
                                    <div className='nextAndPreviousContainer'>
                                        <Button
                                            variant="secondary"
                                            type="button"
                                            onClick={() => handlePrevious()}>
                                            {intl.formatMessage({ id: "previous" })}
                                        </Button>
                                        <Button
                                            variant="primary"
                                            htmlType="submit"
                                        >
                                            {intl.formatMessage({ id: "next" })}
                                        </Button>
                                    </div>
                                    <div className="saveAndExitButton">
                                        <Button
                                            variant="secondary"
                                            htmlType="button"
                                            onClick={() => handleSaveAndExit()}>
                                            {intl.formatMessage({ id: "save_n_exit" })}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

const StyledCharityEntityName = styled(CharityEntityName)`
&.charityEntityName{
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    .googlePlaceInputItem{
        width: 16vw;
    }
    .mainTitle{
        margin-bottom: 20px;
    }
    .errorText{
        color: #ff4d4f;
    }
    .contentContainer{
        display: flex;
        flex-direction: column;
        gap: 12px;
        height: 100vh;
        .formContainer{
        display: flex;
        gap: 12px;
        flex-direction: column;
    }
    }
    .addButton{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .checkboxGroup{
        display: flex;
        flex-direction: column;
    }
    .optionsContainer{
        display: flex;
        align-items: center;
        gap: 30px;
        .for{
            margin-bottom: 10px;
        }
    }
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }

    .radioGroup {
            display: flex;
            flex-direction: column;
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
    .formItemTextField{
        .ant-form-item-control-input{
            width: 34.5vw !important;
        }
    }
    .formItemTextFieldPart{
        .ant-form-item-control-input{
            width: 16vw !important;
        } 
    }
    .helperText {
    color: #5271FF !important;
    font-weight: bold !important;
    margin-bottom: 0px !important;
  }
  .ant-form-item{
    margin-bottom: 10px;
  }
  .tipsContainer{
    background: #D7FEFB;
    border: 1px solid #04ac9c;
    padding: 20px;
    border-radius: 10px;
    width: max-content;
    height: max-content;
    .titleContainer {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        gap: 10px;
    }
  }
  .formItemInput{
    width: 300px;
  }
}`;

export default StyledCharityEntityName;
