import React, { useEffect } from "react";
import { Col, Row, Card, Spin } from "antd";
import { Helmet as HelmetAsync } from "react-helmet-async";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { Pie, Column, Bar } from '@ant-design/plots';
import clsx from "clsx";
import * as constants from "../../constants";
import {
  tokenSelector,
  progressAnalyticsSelector,
  responderTypeAnalyticsSelector,
  submissionsByPhaseSelector,
  themeAnalyticsSelector,
  popularCategoryAnalyticsSelector,
  popularSubCategoryAnalyticsSelector
} from "../../redux/selectors";
import {
  useAppDispatch,
  useAppSelector
} from "../../redux/store";
import {
  getAllAssets,
  getProgressAssetAnalytics,
  getResponderTypeAssetAnalytics,
  getSubmissionsByPhaseAnalytics,
  getThemeAnalytics,
  getPopularCategoryAnalytics,
  getPopularSubCategoryAnalytics,
} from "../../redux/actions";
import StyledText from "../../components/StyledText";
import { sortData } from "../../utils/utilities";

const Insights = ({ className }) => {
  const intl = useIntl();
  const baseClassName = clsx("insights", className);
  const assetID = sessionStorage.getItem('assetID');
  const phaseId = sessionStorage.getItem('phaseId');
  const dispatch = useAppDispatch();
  const token = useAppSelector(tokenSelector) || localStorage.getItem("id_Token");
  const progressAnalytics = useAppSelector(progressAnalyticsSelector);
  const responderTypeAnalytics = useAppSelector(responderTypeAnalyticsSelector);
  const submissionsByPhase = useAppSelector(submissionsByPhaseSelector);
  const themeAnalytics = useAppSelector(themeAnalyticsSelector);
  const popularCategoryAnalytics = useAppSelector(popularCategoryAnalyticsSelector);
  const popularSubCategoryAnalytics = useAppSelector(popularSubCategoryAnalyticsSelector);

  const responderTypeAnalyticsFormattedData = sortData(formatAnalyticsData(responderTypeAnalytics));

  const submissionsByPhaseFormattedData = sortData(formatAnalyticsData(submissionsByPhase?.[0]?.idea));

  const themeAnalyticsFormattedData = sortData(formatThemeAnalyticsData(themeAnalytics));

  const popularCategoryAnalyticsFormattedData = sortData(formatPopularAnalyticsData(popularCategoryAnalytics));

  const popularSubCategoryAnalyticsFormattedData = sortData(formatPopularAnalyticsData(popularSubCategoryAnalytics));

  const PIE_CHART_CONFIG = {
    appendPadding: 10,
    data: responderTypeAnalyticsFormattedData,
    angleField: 'value',
    colorField: 'type',
    color: ["#33307b", "#FDB228", "#EF5644", "#6DBCB6", "#AB7D65"],
    radius: 0.9,
    width: 370,
    autoFit: true,
    legend: {
      layout: 'horizontal',
      position: 'right'
    },
    tooltip: {
      title: 'type',
    },
  };

  const IDEAS_BY_PHASE_CHART_CONFIG = {
    data: submissionsByPhaseFormattedData,
    xField: 'type',
    yField: 'value',
    autoFit: true,
    color: '#33307b',
    label: {
      style: {
        fill: '#FFFFFF',
        opacity: 10,
      },
    },
    xAxis: {
      label: {
        hide: true,
      },
    },
    width: 370,
  };

  const THEME_BAR_CHART_CONFIG = {
    data: popularCategoryAnalyticsFormattedData,
    xField: 'type',
    yField: 'value',
    width: 370,
    color: '#33307b',
  };

  const STACKED_THEME_BAR_CHART_CONFIG = {
    data: themeAnalyticsFormattedData,
    xField: 'category',
    yField: 'value',
    colorField: 'type',
    color: ["#33307b", "#FDB228", "#EF5644", "#6DBCB6", "#AB7D65"],
    stack: true,
  };

  useEffect(() => {
    if (token) {
      dispatch(getAllAssets());
    }
  }, [token]);

  useEffect(() => {
    const data = {
      type: "idea",
      dataType: "submissions",
      assetID,
      ...(phaseId !== 'All' && phaseId && { phase: phaseId })
    };
    dispatch(getProgressAssetAnalytics({ ...data, resultType: "reviewStatusByType" }));
    dispatch(getResponderTypeAssetAnalytics({ ...data, resultType: "submitterType" }));
    dispatch(getThemeAnalytics({ ...data, resultType: "byCategory1" }));
    dispatch(getPopularCategoryAnalytics({ ...data, resultType: "byCategory2" }));
    dispatch(getPopularSubCategoryAnalytics({ ...data, resultType: "byCategory3" }));

    if (phaseId === 'All') {
      dispatch(getSubmissionsByPhaseAnalytics({ ...data, resultType: "submissionsByPhase" }));
    }
  }, [assetID, phaseId])

  return (
    <div className={baseClassName}>
      <HelmetAsync>
        <title>Insights Page</title>
      </HelmetAsync>
      {(phaseId && !(progressAnalytics && Object.keys(progressAnalytics)?.length > 0)) ? (
        <div className="spinner">
          <Spin size="large" />
        </div>
      ) : phaseId && progressAnalytics && Object.keys(progressAnalytics)?.length > 0 && <Row gutter={[16, 24]} className="insightsGraphContainer">
        <StyledEqualHeightCards>
          <Col span={8}>
            <Card>
              <StyledText as="p" variant="H2" className="title">
                {intl.formatMessage({ id: "progress" })}
              </StyledText>
              <div className="contentWrapper">
                {renderContentContainer(intl, progressAnalytics)}
              </div>
            </Card>
          </Col>
          {responderTypeAnalyticsFormattedData?.length > 0 && <Col span={8}>
            <Card>
              <StyledText as="p" variant="H2" className="title">
                {intl.formatMessage({ id: "responder_types" })}
              </StyledText>
              <div className="contentWrapper">
                <Pie {...PIE_CHART_CONFIG} />
              </div>
            </Card>
          </Col>}
          {submissionsByPhaseFormattedData?.length > 0 && <Col span={8}>
            <Card>
              <StyledText as="p" variant="H2" className="title">
                {intl.formatMessage({ id: "ideas_by_phase" })}
              </StyledText>
              <div className="contentWrapper">
                <Column {...IDEAS_BY_PHASE_CHART_CONFIG} />
              </div>
            </Card>
          </Col>}
          {themeAnalyticsFormattedData?.length > 0 && <Col span={8}>
            <Card>
              <StyledText as="p" variant="H2" className="title">
                {intl.formatMessage({ id: "most_popular_theme" })}
              </StyledText>
              <div className="contentWrapper">
                <Bar {...STACKED_THEME_BAR_CHART_CONFIG} width={370} />
              </div>
            </Card>
          </Col>}
          {popularCategoryAnalyticsFormattedData?.length > 0 && <Col span={8}>
            <Card>
              <StyledText as="p" variant="H2" className="title">
                {intl.formatMessage({ id: "most_popular_category" })}
              </StyledText>
              <div className="contentWrapper">
                <Bar {...THEME_BAR_CHART_CONFIG} />
              </div>
            </Card>
          </Col>}
          {popularSubCategoryAnalyticsFormattedData?.length > 0 && <Col span={8}>
            <Card>
              <StyledText as="p" variant="H2" className="title">
                {intl.formatMessage({ id: "most_popular_sub_category" })}
              </StyledText>
              <div className="contentWrapper">
                <Bar {...THEME_BAR_CHART_CONFIG} data={popularSubCategoryAnalyticsFormattedData} />
              </div>
            </Card>
          </Col>}
        </StyledEqualHeightCards>
      </Row>}
    </div>
  );
};

const renderContentContainer = (intl, analytics) => (
  <>
    {renderContent(intl, "ideas", analytics?.All, "#4D88C9")}
    {renderContent(intl, "reviewed", analytics?.Reviewed, "#6DBCB6")}
    {renderContent(intl, "in_progress", analytics?.['In Progress'], "#FDB228")}
    {renderContent(intl, "pending_review", analytics?.Pending, "#EF5644")}
  </>
);

const renderContent = (intl, labelId, value, backgroundColor) => (
  <div className="contentContainer">
    <StyledText as="p" variant="H3">
      {intl.formatMessage({ id: labelId })}
    </StyledText>
    <StyledText as="p" variant="H3" className="progressIdeaValue" style={{ background: backgroundColor }}>
      {value}
    </StyledText>
  </div>
);

const formatAnalyticsData = (data) => (
  data && Object.entries(data)?.map(([type, value]) => ({
    type,
    value
  }))
);

const formatPopularAnalyticsData = (data) => (data?.map(({ category, value }) => ({
  type: category,
  value
}))
);

const formatThemeAnalyticsData = (data) => (data?.map(({ submitter, category, value }) => ({
  category,
  type: submitter,
  value
}))
);

const StyledEqualHeightCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  > .ant-col {
    flex-grow: 1;
    margin-bottom: 16px;
    min-width: 0;
  }
`;

const StyledInsights = styled(Insights)`
  &.insights {
    background: ${(props) => props.theme.backgroundCanvas};
    display: flex;
    flex-direction: column;
    .title {
      margin-bottom: 15px;
    }
    .contentWrapper{
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      .contentContainer{
        display: flex;
        width: 100%;
        justify-content: space-between;
        .progressIdeaValue, .progressReviewedValue, .progressInProgessValue, .progressPendingReviewValue {
          color: ${constants.COLOR_WHITE};
          padding: 5px;
          width: 80px;
          text-align: center;
        }
        .progressIdeaValue{
          background: #4D88C9;
        }
        .progressReviewedValue{
          background: #6DBCB6;
        }
        .progressInProgessValue{
          background: #FDB228;
        }
        .progressPendingReviewValue{
          background: #EF5644;
        }
      }
    }
    .ant-card{
      min-height: 577px; 
    }
    .insightsGraphContainer{
      margin: 30px 0;
    }
  }
`;

export default StyledInsights;
