import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import clsx from "clsx";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { getSecuredUrl } from "../../utils/utilities";
import StyledText from "../../components/StyledText";
import MediaCarousel from "../../components/MediaCarousel";
import {
  programDataSelector,
  opportunityDataSelector,
  toggleSpinnerSelector,
  savedContentData
} from "../../redux/selectors";
import {
  useAppSelector
} from "../../redux/store";
import GrantDetails from "../charityCreate/charityProgramReview/Grants";
import VolunteeringDetails from "../charityCreate/charityProgramReview/Volunteer";
import InKindDetails from "../charityCreate/charityProgramReview/InKind";

const ProgramReview = ({ className }) => {
  const baseClassName = clsx("programReview", className);
  const intl = useIntl();
  const [mediaImages, setMediaImages] = useState([]);
  const showSpinner = useAppSelector(toggleSpinnerSelector);
  const opportunityData = useAppSelector(opportunityDataSelector) || {}
  const programData = useAppSelector(programDataSelector);
  const {
    currencySymbol,
    cashGiving,
  } = programData || {};

  const {
    additionalComment,
    interimChanges,
    interimDateChanges,
    gallery
  } = useAppSelector(savedContentData) || {}

  const { opportunity:
    { opportunitySummary,
      totalCashRequested,
      wholeProjectFunding,
      grantBudgetPercentage = 0,
      donationUse,
      additionalFundsSource,
      fundingSecured,
      oppFullBudgetNotRequired,
      volunteerUse,
      inKindUse,
    } = {} }
    = opportunityData || {};

  useEffect(() => {
    const fetchMediaImages = async () => {
      try {
        if (gallery?.length > 0 && !mediaImages?.length) {
          const promises = gallery.map(async (file) => {
            const securedURI = await getSecuredUrl(file.key);
            const securedURL = URL.createObjectURL(securedURI);
            return { url: securedURL, name: file.name, mediaDescription: file.mediaDescription };
          });
          const resolvedMediaImages = await Promise.all(promises);
          const validMediaImages = resolvedMediaImages.filter(Boolean);
          setMediaImages(validMediaImages);
        }
      } catch (error) {
        console.error('Error fetching media images:', error);
      }
    };

    fetchMediaImages();
  }, [gallery]);

  return (
    <div className={baseClassName}>
      {(!opportunityData || showSpinner) && (
        <div className="overlay">
          <Spin className="spinner" size="large" spinning={!programData || showSpinner} />
        </div>
      )}
      <div className="leftSubContainer">
        <div className="subContainer">
          <StyledText as="p" variant="H2">
            {intl.formatMessage({ id: "summary_title" })}
          </StyledText>
          <StyledText as="p" variant="B3">
            {opportunitySummary}
          </StyledText>
        </div>
        <div className="supportFlexContainer">
          {cashGiving === 'Y' && <GrantDetails
            currencySymbol={currencySymbol}
            totalCashRequested={totalCashRequested}
            wholeProjectFunding={wholeProjectFunding}
            grantBudgetPercentage={grantBudgetPercentage}
            donationUse={donationUse}
            additionalFundsSource={additionalFundsSource}
            fundingSecured={fundingSecured}
            oppFullBudgetNotRequired={oppFullBudgetNotRequired}
            intl={intl}
          />}
          {volunteerUse?.length > 0 && <VolunteeringDetails
            title={intl.formatMessage({ id: "volunteering" })}
            volunteerUse={volunteerUse}
          />}
          {inKindUse?.length > 0 && <InKindDetails
            title={intl.formatMessage({ id: "inkind" })}
            inKindUse={inKindUse}
          />}
        </div>
        <div className="subWhiteContainer">
          <StyledText as="p" variant="H2">
            {intl.formatMessage({ id: "how_have_you_used_the_support_provided_so_far" })}
          </StyledText>
          <StyledText as="p" variant="B3">
            {additionalComment}
          </StyledText>
        </div>
        <div className="subWhiteContainer">
          <StyledText as="p" variant="H2">
            {intl.formatMessage({ id: "are_there_any_changes_to_your_planned_expenditure_and_if_so_why" })}
          </StyledText>
          <StyledText as="p" variant="B3">
            {interimChanges}
          </StyledText>
        </div>
        <div className="subWhiteContainer">
          <StyledText as="p" variant="H2">
            {intl.formatMessage({ id: "are_there_any_changes_to_your_estimated_completion_date_and_if_so_why" })}
          </StyledText>
          <StyledText as="p" variant="B3">
            {interimDateChanges}
          </StyledText>
        </div>
        {Array.isArray(gallery) && gallery?.length > 0 && <div className="subContainer mediaSection">
          <StyledText as="p" variant="H2">
            {intl.formatMessage({ id: "media" })}
          </StyledText>
          {mediaImages?.length === gallery?.length && <MediaCarousel data={mediaImages} />}
        </div>}
      </div>
    </div>
  );
};

const StyledProgramReview = styled(ProgramReview)`
  &.programReview {
    display: flex;
    gap: 24px;
    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.45);
    }
        .spinner {
        z-index: 2000;
        margin: auto;
        left: 50%;
        right: 50%;
        position: absolute;
        bottom: 50%;
        }
            ul {
            margin: 0;
            }

    .leftSubContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
    } 

    .whiteSupportContainer{
        background: white;
        box-shadow: 0px 3px 16px #0000000d;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 16px;
          width: 35vw;
          &.volunteeringContainer {
         box-shadow: 0px 3px 16px #0000000d;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
    }

    .ant-collapse-expand-icon {
      color: #313131 !important;
    }

    .mediaSection{
        margin-bottom: 20px;
    }

    .flexBox{
        display: flex;
        align-items: flex-start;
        gap: 20px;
        &.justifyContent{
            justify-content: flex-start;
        }
        .flexBoxChild{
            display: flex;
            flex-direction: column;
            gap: 10px;
            .flexColumn{
                display: flex;
                flex-direction: column;
                .noBreak{
                    white-space: nowrap;
                }
            }
        }
    }

    .divider{
        height: 100%;
    margin-inline: 10px;
    }
    .outputNumber{
        background: #dee3ed;
        border-radius: 5px;
        padding: 1px 8px;
        border: 1px solid grey; 
    }

    .supportFlexContainer {
      display: flex;
      gap: 30px;
      flex-direction: column;

      .supportContainer {
        padding: 16px;
        width: max-content;
        width: 35vw;

        &.grantContainer {
          background: transparent linear-gradient(223deg, #ffffff 0%, #b2f9bd 100%) 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 16px #0000000d;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }

        &.volunteeringContainer {
          background: transparent linear-gradient(223deg, #ffffff 0%, #8bdaff 100%) 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 16px #0000000d;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }

        &.inKindContainer {
          background: transparent linear-gradient(223deg, #ffffff 0%, #ffaffa 100%) 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 16px #0000000d;
          border-radius: 10px;
        }

        
      }
    }
    .titleContainer {
          display: flex;
          gap: 2px;
          align-items: center;
        }
    }
    .helperText {
    color: #5271FF !important;
  }

    .subContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .descriptionContainer{
        max-width: 70ch;
            word-wrap: break-word;
            overflow-wrap: break-word;
        p, li{
            font-size: 14px;
            font-weight: 500;
            color: #242424;
            font-family: sans-serif;
            max-width: 70ch;
            word-wrap: break-word;
            overflow-wrap: break-word;
        }
      }
    }

    .subWhiteContainer{
      display: flex;
      flex-direction: column;
      gap: 10px;
      background: white;
      border-radius: 10px;
      padding: 10px;
    }

    .ant-collapse-expand-icon {
      transform: rotate(90deg) translate(-5px);
      svg{
            fill: #313131 !important;
        }
    }

    .ant-collapse-item-active {
      .ant-collapse-expand-icon {
        transform: rotate(0deg) translate(-5px);
      }
    }

    .ant-timeline-item-head {
      background: transparent !important;
    }

    .anticon-check {
      color: #58b55e;
    }

  }
`;

export default StyledProgramReview;