import React from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Divider, Form, Spin } from "antd";
import styled from "styled-components";
import {
    toggleSpinner
} from '../../redux/actions';
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import {
    toggleSpinnerSelector,
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";

const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 20 },
};

const CharityProfileCreateStart = ({ className }) => {
    const baseClassName = clsx("charityProfileCreateStart", className);
    const intl = useIntl();
    const [form] = Form.useForm();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleProceed = () => {
        dispatch(toggleSpinner(true));
        navigate("/impact-maker/profile/create/entity-name");
        dispatch(toggleSpinner(false));
    }

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    return (
        <div className={baseClassName}>
            <StyledText as="p" variant="H1" className='mainTitle'>
                {intl.formatMessage({ id: "create_your_profile" })}
            </StyledText>
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <Form
                {...formItemLayout}
                labelAlign="left"
                form={form}
                onFinish={handleProceed}
                onFinishFailed={onFinishFailed}
                className="formContainer"
                scrollToFirstError
            >
                <StyledText as="p" variant="B3">
                    {intl.formatMessage({ id: "on_the_following_screens_you_will_be_asked_to_complete_a_profile_of_your_entity" })}
                </StyledText>
                <StyledText as="p" variant="H2">
                    {intl.formatMessage({ id: "how_your_profile_is_used" })}
                </StyledText>
                <StyledText as="p" variant="B3">
                    {intl.formatMessage({ id: "once_your_profile_is_complete_it_will_be_automatically_attached_to_any_application_you_submit_through_bizGive" })}
                </StyledText>
                <StyledText as="p" variant="B3">
                    {intl.formatMessage({ id: "companies_and_bizGive_use_your_profile_to_perform_background_due_diligence_checks_on_your_organisation_it_is_only_visible_to_companies_you_apply_to" })}
                </StyledText>
                <StyledText as="p" variant="B3">
                    {intl.formatMessage({ id: "once_complete_you_will_be_able_to_apply_to_as_many_company_programmes_as_you_re_eligible_for_from_the_discover_tab" })}
                </StyledText>
                <StyledText as="p" variant="H2" className='standardMargin'>
                    {intl.formatMessage({ id: "profile_guidance" })}
                </StyledText>
                <div className="contentContainer">
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "next_title" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "saves_your_data_and_moves_you_to_the_next_screen_your_answers_will_automatically_be_saved_unless_you_have_an_internet_connectivity_issue" })}
                    </StyledText>
                </div>
                <div className="contentContainer">
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "save_n_exit_title" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "saves_your_data_and_logs_you_out_of_bizGive" })}
                    </StyledText>
                </div>
                <div className='buttonContainer'>
                    <Divider />
                    <Button
                        className='proceedButton'
                        variant="primary"
                        htmlType="submit"
                    >
                        {intl.formatMessage({ id: "start" })}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

const StyledCharityProfileCreateStart = styled(CharityProfileCreateStart)`
&.charityProfileCreateStart{
    background: white;
    display: flex;
    padding: 20px;
    flex-direction: column;
    height: 100vh;
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }
    .mainTitle{
        margin-bottom: 20px;
    }
    .radioGroup{
        display: flex;
        flex-direction: column;
    }
    .formContainer{
        display: flex;
        gap: 20px;
        flex-direction: column;
        .contentContainer{
        display: flex;
        gap: 4px;
        }
        .standardMargin{
            margin-top: 10px;
        }
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -30px;
        .proceedButton{
            margin-bottom: 15px;
        }
    }
}`;

export default StyledCharityProfileCreateStart;