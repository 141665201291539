import * as constants from "../constants";

export const theme = {
  textStyles: {
    T1: {
      fontSize: 56,
      fontWeight: constants.FONT_WEIGHT_MEDIUM,
      color: "#FFFFFF",
      lineHeight: 64,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    T2: {
      fontSize: 32,
      fontWeight: constants.FONT_WEIGHT_SEMIBOLD,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 40,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    H1: {
      fontSize: 20,
      fontWeight: constants.FONT_WEIGHT_SEMIBOLD,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 24,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    H1a: {
      fontSize: 20,
      fontWeight: constants.FONT_WEIGHT_SEMIBOLD,
      color: constants.COLOR_BIZ_GIVE_THEME,
      lineHeight: 24,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    H1b: {
      fontSize: 20,
      fontWeight: constants.FONT_WEIGHT_REGULAR,
      color: constants.COLOR_BIZ_GIVE_THEME,
      lineHeight: 24,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    H1c: {
      fontSize: 20,
      fontWeight: constants.FONT_WEIGHT_REGULAR,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 24,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    H2: {
      fontSize: 16,
      fontWeight: constants.FONT_WEIGHT_SEMIBOLD,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 20,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    H3: {
      fontSize: 16,
      fontWeight: constants.FONT_WEIGHT_MEDIUM,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 20,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B1: {
      fontSize: 20,
      fontWeight: constants.FONT_WEIGHT_REGULAR,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 28,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B2: {
      fontSize: 16,
      fontWeight: constants.FONT_WEIGHT_MEDIUM,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 20,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B2a: {
      fontSize: 16,
      fontWeight: constants.FONT_WEIGHT_REGULAR,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 24,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B2b: {
      fontSize: 16,
      fontWeight: constants.FONT_WEIGHT_REGULAR,
      color: constants.B3A_COLOR,
      lineHeight: 24,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B3: {
      fontSize: 14,
      fontWeight: constants.FONT_WEIGHT_REGULAR,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 22,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B3a: {
      fontSize: 14,
      fontWeight: constants.FONT_WEIGHT_REGULAR,
      color: constants.B3A_COLOR,
      lineHeight: 22,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B3b: {
      fontSize: 14,
      fontWeight: constants.FONT_WEIGHT_REGULAR,
      color: constants.B3B_COLOR,
      lineHeight: 22,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B3c: {
      fontSize: 14,
      fontWeight: constants.FONT_WEIGHT_MEDIUM_BOLD,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 22,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B3d: {
      fontSize: 14,
      fontWeight: constants.FONT_WEIGHT_SEMIBOLD,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 22,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B3e: {
      fontSize: 14,
      fontWeight: constants.FONT_WEIGHT_SEMIBOLD,
      color: constants.COLOR_BIZ_GIVE_THEME,
      lineHeight: 22,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B3f: {
      fontSize: 14,
      fontWeight: constants.FONT_WEIGHT_SEMIBOLD,
      color: "#ED6C2D",
      lineHeight: 22,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    CTA: {
      fontSize: 14,
      fontWeight: constants.FONT_WEIGHT_MEDIUM,
      color: "#ED6C2D",
      lineHeight: 20,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    CTAa: {
      fontSize: 16,
      fontWeight: constants.FONT_WEIGHT_MEDIUM,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 20,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B4: {
      fontSize: 12,
      fontWeight: constants.FONT_WEIGHT_REGULAR,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 20,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
    B5: {
      fontSize: 12,
      fontWeight: constants.FONT_WEIGHT_REGULAR,
      color: constants.COLOR_GREY_DARK,
      lineHeight: 20,
      letterSpacing: 0,
      opacity: 70,
      fontFamily: constants.POPPINS_FONT_FAMILY,
    },
  },
  buttons: {
    primary: {
      default: {
        color: "#FFFFFF",
        background: "#04ac9c",
        borderWidth: constants.BORDER_SIZE_DEFAULT,
        borderRadius: constants.BORDER_RADIUS_DEFAULT,
        fontSize: 14,
        alignment: constants.TEXT_ALIGNMENT_CENTER,
        borderColor: "#04ac9c",
        padding: constants.BORDER_RADIUS_DEFAULT,
        fontWeight: constants.FONT_WEIGHT_MEDIUM,
        lineHeight: 20,
        letterSpacing: 0,
        opacity: 100,
        fontFamily: constants.POPPINS_FONT_FAMILY,
      },
      hover: {
        color: "#FFFFFF",
        background: "#00877A",
        borderWidth: constants.BORDER_SIZE_DEFAULT,
        borderRadius: constants.BORDER_RADIUS_DEFAULT,
        fontSize: 14,
        alignment: constants.TEXT_ALIGNMENT_CENTER,
        borderColor: "#00877A",
        lineHeight: 20,
        letterSpacing: 0,
        opacity: 100,
        fontFamily: constants.POPPINS_FONT_FAMILY,
      },
      disabled: {
        color: "#242424",
        background: "#eeeff1",
        borderWidth: constants.BORDER_SIZE_DEFAULT,
        borderRadius: constants.BORDER_RADIUS_DEFAULT,
        fontSize: 14,
        alignment: constants.TEXT_ALIGNMENT_CENTER,
        borderColor: "#D9D9D9",
        lineHeight: 20,
        letterSpacing: 0,
        opacity: 100,
        fontFamily: constants.POPPINS_FONT_FAMILY,
      },
    },
    secondary: {
      default: {
        color: "#242424",
        background: "#FFFFFF",
        borderWidth: constants.BORDER_SIZE_DEFAULT,
        borderRadius: constants.BORDER_RADIUS_DEFAULT,
        borderColor: "#04ac9c",
        fontSize: 14,
        padding: constants.BORDER_RADIUS_DEFAULT,
        lineHeight: 20,
        letterSpacing: 0,
        opacity: 100,
        fontFamily: constants.POPPINS_FONT_FAMILY,
      },
      hover: {
        color: "#242424",
        background: "#60f8ea",
        borderWidth: constants.BORDER_SIZE_DEFAULT,
        borderRadius: constants.BORDER_RADIUS_DEFAULT,
        borderColor: "#04ac9c",
        fontSize: 14,
        alignment: constants.TEXT_ALIGNMENT_CENTER,
        lineHeight: 20,
        letterSpacing: 0,
        opacity: 100,
        fontFamily: constants.POPPINS_FONT_FAMILY,
      },
      disabled: {
        color: "#242424",
        background: "#eeeff1",
        borderWidth: constants.BORDER_SIZE_DEFAULT,
        borderRadius: constants.BORDER_RADIUS_DEFAULT,
        fontSize: 14,
        alignment: constants.TEXT_ALIGNMENT_CENTER,
        borderColor: "#D9D9D9",
        lineHeight: 20,
        letterSpacing: 0,
        opacity: 100,
        fontFamily: constants.POPPINS_FONT_FAMILY,
      },
    },
    tertiary: {
      default: {
        color: "#FFFFFF",
        background: constants.PRIMARY_DEFAULT_MENU_COLOR,
        borderWidth: constants.BORDER_SIZE_DEFAULT,
        borderRadius: constants.BORDER_RADIUS_DEFAULT,
        fontSize: 14,
        alignment: constants.TEXT_ALIGNMENT_CENTER,
        borderColor: constants.PRIMARY_DEFAULT_MENU_COLOR,
        padding: constants.BORDER_RADIUS_DEFAULT,
        fontFamily: constants.POPPINS_FONT_FAMILY,
      },
      hover: {
        color: "#FFFFFF",
        background: constants.PRIMARY_DEFAULT_MENU_COLOR,
        borderWidth: constants.BORDER_SIZE_DEFAULT,
        borderRadius: constants.BORDER_RADIUS_DEFAULT,
        fontSize: 14,
        alignment: constants.TEXT_ALIGNMENT_CENTER,
        borderColor: constants.PRIMARY_DEFAULT_MENU_COLOR,
        fontFamily: constants.POPPINS_FONT_FAMILY,
      },
      disabled: {
        color: "#24242440",
        background: constants.PRIMARY_DEFAULT_MENU_COLOR,
        borderWidth: constants.BORDER_SIZE_DEFAULT,
        borderRadius: constants.BORDER_RADIUS_DEFAULT,
        fontSize: 14,
        alignment: constants.TEXT_ALIGNMENT_CENTER,
        borderColor: constants.PRIMARY_DEFAULT_MENU_COLOR,
        fontFamily: constants.POPPINS_FONT_FAMILY,
      },
    },
    danger: {
      default: {
        color: "#FFFFFF",
        background: "#FF4D4F",
        borderWidth: constants.BORDER_SIZE_DEFAULT,
        borderRadius: constants.BORDER_RADIUS_DEFAULT,
        fontSize: 14,
        alignment: constants.TEXT_ALIGNMENT_CENTER,
        borderColor: "#FF4D4F",
        padding: constants.BORDER_RADIUS_DEFAULT,
        fontFamily: constants.POPPINS_FONT_FAMILY,
      },
      hover: {
        color: "#FFFFFF",
        background: "#D9363E",
        borderWidth: constants.BORDER_SIZE_DEFAULT,
        borderRadius: constants.BORDER_RADIUS_DEFAULT,
        fontSize: 14,
        alignment: constants.TEXT_ALIGNMENT_CENTER,
        borderColor: "#D9363E",
        fontFamily: constants.POPPINS_FONT_FAMILY,
      },
      disabled: {
        color: "#FFFFFF80",
        background: "#FF4D4F",
        borderWidth: constants.BORDER_SIZE_DEFAULT,
        borderRadius: constants.BORDER_RADIUS_DEFAULT,
        fontSize: 14,
        alignment: constants.TEXT_ALIGNMENT_CENTER,
        borderColor: "#FF4D4F",
        fontFamily: constants.POPPINS_FONT_FAMILY,
      },
    },
    
  },
  backgroundCanvas: constants.BACKGROUND_CANVAS_COLOR,
  backgroundCanvas1: constants.BACKGROUND_CANVAS_COLOR1,
  tags: {
    invited: {
      background: "#fff7f5",
      border: "#ED6C2D",
      borderSize: "1px",
      edges: "4px",
      fontSize: 14,
      fontWeight: constants.FONT_WEIGHT_REGULAR,
      color: constants.B3B_COLOR,
      lineHeight: 22,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
      textColor: "#ED6C2D",
      alignment: "centre",
    },
    admin: {
      background: "#e0efed",
      border: "#04ac9c",
      borderSize: "1px",
      edges: "4px",
      fontSize: 14,
      fontWeight: constants.FONT_WEIGHT_REGULAR,
      color: constants.B3B_COLOR,
      lineHeight: 22,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
      textColor: "#04ac9c",
      alignment: "centre",
    },
    regular: {
      background: "#e9e8fa",
      border: "#5550F7",
      borderSize: "1px",
      edges: "4px",
      fontSize: 14,
      fontWeight: constants.FONT_WEIGHT_REGULAR,
      color: constants.B3B_COLOR,
      lineHeight: 22,
      letterSpacing: 0,
      opacity: 100,
      fontFamily: constants.POPPINS_FONT_FAMILY,
      textColor: "#5550F7",
      alignment: "centre",
    },
  },
  primaryColors: {
    defaultMenu: "#6F6AF8",
    primary1: constants.PRIMARY_COLOR_1,
    primary2: constants.PRIMARY_COLOR_2,
  },
  secondaryColors: {
    secondary1: constants.SECONDARY_COLOR_1,
    secondary2: constants.SECONDARY_COLOR_2,
  },
  supportingColors: {
    supporting1: constants.SUPPORTING_COLOR_1,
    supporting2: constants.SUPPORTING_COLOR_2,
    supporting3: constants.SUPPORTING_COLOR_3,
    supporting4: constants.SUPPORTING_COLOR_4,
    supporting5: constants.SUPPORTING_COLOR_5,
  },
  baseColors: {
    base1: constants.BASE_COLOR_1,
    base2: constants.BASE_COLOR_2,
    base3: constants.BASE_COLOR_3,
  },
  breakpoints: {
    small: "768px",
    medium: "992px",
    large: "1024px",
  },
  bizGiveThemeColor: constants.COLOR_BIZ_GIVE_THEME
};
